<main>
    <button *ngIf="!downloading" mat-button class="close-window-btn" (click)="onCloseWindow()">
        <mat-icon>clear</mat-icon>
    </button>    

    <!-- <article
        style="width: 100%; margin: 0px 0px 0px; position: relative;"
        [ngStyle]="{'transform': downloading ? 'rotate(90deg)' : 'unset'}"
        id="letterBodyContainer"> -->

    <!-- <article
        #pdfContainer
        style="transform: rotate(90deg); width: 100%; margin: 0px 0px 0px; position: relative;"
        id="letterBodyContainer"> -->

    <!-- <article
        #pdfContainer
        id="letterBodyContainer"
        style="overflow-y: auto; width: 100%; margin: 0px 0px 0px; position: relative; padding: 0px 40px 45px; height: 775px; background-image: url('../../../../assets/images/background1.jpg'); background-size: contain; background-repeat: no-repeat;"> -->

    <!-- <article
        #pdfContainer
        id="letterBodyContainer"
        style="overflow-y: auto; width: 100%; margin: 0px 0px 0px; position: relative; padding: 0px 40px 45px; height: 815px; background-image: url('../../../../assets/images/background1.jpg'); background-size: 1122px; background-repeat: no-repeat;background-position: -7px 20px;"> -->

    <article
        #pdfContainer
        id="letterBodyContainer"
        style="overflow-y: auto; width: 100%; margin: 0px 0px 0px; position: relative; padding: 0px;  background-image: url('../../../../assets/images/background2.fw.png'); background-size: 1122px; background-repeat: no-repeat; background-position: -7px 20px;height: fit-content;">

        <!-- <div>
            <img src="./assets/images/background1.jpg" alt="">
        </div> -->

        <!-- <button 
            mat-button
            color="primary"
            class="download-button"
            (click)="onDownloadLetter()">
            <div class="button-label">
                <mat-icon>cloud_download</mat-icon>
                <span>Download </span>
            </div>
        </button> -->
        
        <!-- <section 
            class="letter-body" 
            style="padding: 0px 40px 45px; height: 775px; background-image: url('../../../../assets/images/background1.jpg'); background-size: contain; background-repeat: no-repeat;">
         -->

        <section style=" margin: 250px 0px 0px;">

            <!-- <h3 style="font-size: 20px; text-transform: capitalize; text-align: center; position: absolute;   font-family: initial; font-weight: 300; color: #474747;left: 170px;top: 94px;">
                Regulation 19(2)
            </h3>

            <h3 style="font-size: 20px; text-transform: uppercase; position: absolute; left: 170px; top: 118px; font-family: none; font-weight: 700; color: #474747;">
                FORM 4 
           </h3>             -->

           <!-- <section style="position: absolute; top: 280px; width: calc(100% - 68px);"> -->
           <section>

               <h1 style="margin: 10px 0px 25px; font-size: 22px; text-transform: uppercase; font-weight: 500; color: #000; text-align: center; width: 100%;font-family: serif;letter-spacing: 1px;">
                   Certificate of Registration
               </h1>            

               <h2 style="font-size: 19px; text-transform: uppercase; font-weight: 300; color: #474747;text-align: center;width: 100%">
                    I certify that
                </h2>

               <div 
                    style="border-bottom: 2px dotted; font-size: 15px; text-align: center; width: calc(100% - 35%); margin: 20px auto 0px; font-weight: 700; font-style: italic;padding-bottom: 4px;color: #000;" 
                    [innerHTML]="data.Organisation">
                </div>

               <p style="color: #000; width: 100%; text-align: center; font-size: 17px; line-height: 22px; margin: 28px 0px 25px; font-weight: 300;">
                   has this day been registered as <br />

                   <b style="font-weight: 700;">a data collector/data processor/data controller</b> under <br />

                    the Data Protection and Privacy Act, 2019 under the following
                </p>

                <div style="color: #000; width: 100%; font-size: 16px; line-height: 22px; margin: 30px 0px 25px; display: flex; flex-direction: row; align-content: center; justify-content: center; align-items: flex-start;">
                    registration number 
                    <div 
                        style="text-align: center; font-size: 16px;  border-bottom: 2px dotted; padding: 0px 8px 0px; color: #000; margin: -8px 7px 0px; height: 25px; font-style: italic; font-weight: 700; min-width: 243px;" 
                        [innerHTML]="data.RegNubmer">
                    </div>
                </div>                

                <!-- <div style="color: #000; width: 100%;  font-size: 16px;  line-height: 27px; margin: 117px 0px 0px 78px; display: flex; flex-direction: row;align-content: center;justify-content: center;align-items: flex-start;">
                    <div 
                        style="text-align: center; font-size: 14px; padding: 0px 8px 0px; color: #000; margin: -8px 7px 0px; height: 25px; font-style: italic; font-weight: 700; min-width: 243px;" 
                        [innerHTML]="data.RegNubmer">
                    </div>
                </div>                 -->

                <p style="color: #000; width: 100%;text-align: center;font-size: 17px;line-height: 25px;margin: -25px 0px 25px;font-weight: 300;">
                    This certificate is valid for twelve months from the data of issue.
                </p>

                <div style="color: #000; width: 100%; font-size: 16px; line-height: 27px; margin: 33px 0px 25px; display: flex; flex-direction: row; align-content: center; justify-content: center; align-items: flex-start;">
                    Dated this <div style="font-size: 16px; border-bottom: 2px dotted; padding: 0px 20px 0px; color: #000;   font-style: italic; font-weight: bold;margin: -11px 7px 0px;height: 28px;">{{ data.DateOfIssue | date : 'dd' }} <sup style="font-size: 16px;font-family: none;">{{ getDateSuperscript() }}</sup></div> 
                    day of <i style="font-size: 16px; border-bottom: 2px dotted; padding: 0px 20px 0px; color: #000; font-style: italic; font-weight: bold; margin: -8px 7px 0px;height: 25px;">{{ data.DateOfIssue | date : 'MMMM' }}</i>
                    the year <i style="font-size: 16px; border-bottom: 2px dotted; padding: 0px 20px 0px; color: #000;   font-style: italic; font-weight: bold;margin: -8px 7px 0px;height: 25px;">{{ data.DateOfIssue | date : 'YYYY' }}</i>
                </div>

                <!-- <div style="color: #000; width: 100%;  font-size: 16px;  line-height: 27px;  margin: 48px 0px 0px; display: flex; flex-direction: row;align-content: center;justify-content: center;align-items: flex-start;">
                    <div style="font-size: 14px; padding: 0px 20px 0px; color: #000; font-style: italic; font-weight: bold; margin: -11px -18px 0px 66px; height: 28px;">{{ data.DateOfIssue | date : 'dd' }} <sup style="font-size: 16px;font-family: none;">{{ getDateSuperscript() }}</sup></div> 
                    <i style="font-size: 14px; padding: 0px 20px 0px; color: #000; font-style: italic; font-weight: bold; margin: -6px 6px 0px 51px; height: 25px;">{{ data.DateOfIssue | date : 'MMMM' }}</i>
                    <i style="font-size: 14px; padding: 0px 20px 0px; color: #000; font-style: italic; font-weight: bold; margin: -6px 0px 0px 51px; height: 25px;">{{ data.DateOfIssue | date : 'YYYY' }}</i>
                </div> -->

                <!-- <div style="color: #000; width: 100%; font-size: 16px; display: flex; flex-direction: column; align-items: center; margin: 18px 0px 0px; text-align: center;">
                    <div style="font-size: 15px; min-width: 240px; text-align: center; margin: 20px auto 0px; font-weight: 700;font-style: italic; padding-bottom: 4px;color: #000;" [innerHTML]="data.Director"></div>
                </div>                -->

                <div style="color: #000; width: 100%; font-size: 16px; display: flex; flex-direction: column; align-items: center; margin: 40px 0px 0px; text-align: center;">
                    <div style="min-width: 240px; text-align: center;border-bottom: 2px dotted;margin: 20px auto 0px;font-weight: 700;font-style: italic;padding-bottom: 4px;color: #000;" [innerHTML]="data.Director"></div>
                    <p style="font-size: 16px;  color: #000; margin: 8px 0px 1px 0px;font-weight: 600;">National Personal Data Protection Director</p>
                </div>               

                <p style="font-size: 14px; width: calc(100% - 68px); text-align: center; color: #000; font-weight: 400; margin: 40px 0px 25px;">
                    This certificate shall remail in force unless revoked by the Personal Data Protection Office
                </p>
                  
           </section>

        </section>

    </article>
</main>