<main>

    <section>

        <p [ngStyle]="{'margin-top': marginTop}">{{ title }}</p>
        
        <img src="./assets/icons/Final-Icons-30.png" alt="">

    </section>

</main>