<main>
    <button mat-button [disabled]="donwloading" class="close-window-btn" (click)="onCloseWindow()">
        <mat-icon>clear</mat-icon>
    </button>

    <article
        style="width: 100%; margin: 0px 0px 30px; position: relative;"
        id="letterBodyContainer">
        
        <section class="letter-body" style="padding: 65px 40px 45px;font-family: none;">

            <h1 style="font-weight: 700;font-size: 17px;text-transform: uppercase;text-align: center;font-family: none;color: #000;margin-top: 0px;">
                COMPLAINT LETTER
            </h1>

            <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 2px;">
                <p style="font-size: 15px; font-family: none; line-height: 21px; color: #000000; margin: 0px;text-transform: uppercase;font-weight: 600;">
                    {{ data.ComplaintForm.LastName }} {{ data.ComplaintForm.FirstName }}
                </p>

                <p style="font-size: 15px; font-family: none; line-height: 21px; color: #000000; margin: 0px;text-transform: uppercase;font-weight: 600;">
                    {{ data.ComplaintForm.PhysicalAddress }}
                </p>

                <p style="font-size: 15px; font-family: none; line-height: 21px; color: #000000; margin: 0px;text-transform: uppercase;font-weight: 600;">
                    {{ data.ComplaintForm.PhoneNumber }}
                </p>

                <div style="text-align: left; font-size: 15px; font-family: none; line-height: 27px; color: #1c1c1c; display: flex; flex-direction: row; margin: 0px 0px 10px;font-weight: 600;text-transform: uppercase;">
                    {{ data.Date | date : 'dd' }} 
                    <sup style="font-size: 14px; font-family: none; margin: -3px 5px 0px 2px;text-transform: lowercase;">{{ getDateSuperscript() }}</sup>
                    {{ data.Date | date : 'MMMM YYYY' }}.
                </div>                
            </div>    
            
            <p style="font-weight: 600;font-size: 15px;font-family: none;line-height: 21px;color: #000000;text-align: left;">
                {{ data.Organisation ? data.Organisation.OrganisationName : data.ComplaintForm.OtherOrganisationComplainedTo }}
            </p>

            <p style="font-size: 15px; font-family: none; line-height: 21px; color: #000000; margin: -10px 0px 25px;max-width: 230px;text-align: left;">
                {{ data.Organisation ? data.Organisation.Location : data.ComplaintForm.OtherOrganisationComplainedToAddress }}
            </p>
            
            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;margin: 0px 0px 20px;">
                Dear Sir/Madam
            </p>

            <h1 style="font-weight: 700; font-size: 16px; text-transform: uppercase; text-align: left; font-family: none; color: #000; margin-top: 0px;">
                Complaint in relation to handling of my personal data in your possession
            </h1>            

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                My name is {{ data.ComplaintForm.LastName }} {{ data.ComplaintForm.FirstName }} 
                <span style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;" *ngIf="data.ComplaintForm.ComplainerCategory !== 'Individual'">
                    from {{ data.OrganisationComplainedFrom }}
                </span>
                located at {{ data.ComplaintForm.PhysicalAddress }}
                {{ data.ComplaintForm.IsCustomerToOrganisation === 'Yes' ? 'and I am a Customer' : 'and I am not a customer' }}.
            </p>

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                I am concerned that you have not handled my personal data properly.
            </p>

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                The following Personal Data was affected
            </p>
            <div *ngFor="let category of data.ComplaintForm.PersonalDataAffected">
                
                <li style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">{{category.DataSubCategory}}</li>
            </div> 

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                {{ data.ComplaintForm.FullDetailsOfComplaint }}
            </p>

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                I understand that before reporting my concern to the Personal Data Protection Office (PDPO) I should give you the chance to deal with it.
            </p>

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                Please send a response within one calendar month. If you cannot respond within that period, please tell me when you will be able to respond.
            </p>

            <p style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                If there is anything you would like to discuss, please contact me on the following number 
                {{ data.ComplaintForm.PhoneNumber }} {{ data.ComplaintForm.EmailAddress ? ' or/and email address ' + data.ComplaintForm.EmailAddress : '' }}.
            </p>
            
            <p style="margin-top: 34px;font-size: 15px;font-family: none;line-height: 21px;color: #000000;">
                Yours faithfully,
            </p>
            
            <div style="font-size: 15px; font-family: none;margin-top: 35px;">
                <div style="width: 290px; border-bottom: 1px dashed black; "></div>
                <p style="font-size: 15px;font-family: none;color: #000;margin: 8px 0px 1px 0px;">[Name and Signature]</p>
            </div>            
        </section>

    </article>
</main>