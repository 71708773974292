<main>

    <button 
        mat-button 
        matTooltip="File A Complaint" 
        [matTooltipPosition]="'before'">

        <div class="btn-label">

            <mat-icon>post_add</mat-icon>

            <!-- <span>Application for Registration</span> -->

        </div>

    </button>

    <button 
        mat-button 
        matTooltip="Application for Registration" 
        [matTooltipPosition]="'before'">

        <div class="btn-label">

            <mat-icon>post_add</mat-icon>

            <!-- <span>Application for Registration</span> -->

        </div>

    </button>

</main>