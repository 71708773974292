// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // cmsBaseUrlProxy: 'http://52.56.51.92:9093/',
  // cmsMediaProxy: 'http://52.56.51.92:9093/',
  // cmsBaseUrlProxy: 'https://appliedprinciplesenv.com/cms-api/',
  // cmsMediaProxy: 'https://appliedprinciplesenv.com/cms-media/',
  // pythonApi: 'https://appliedprinciplesenv.com/api/v1.0/',
  // backOffice: 'https://login.pdpo.go.ug',
  
  // BaseUrl: 'https://pdpo.go.ug',
  // cmsBaseUrlProxy: 'https://pdpo.go.ug/cms/',
  // cmsMediaProxy: 'https://pdpo.go.ug/media/',
  // pythonApi: 'https://pdpo.go.ug/api/v1.0/',
  // backOffice: 'https://login.pdpo.go.ug'  

  BaseUrl: 'https://test.pdpo.go.ug',
  cmsBaseUrlProxy: 'https://pdpo.go.ug/cms/',
  cmsMediaProxy: 'https://pdpo.go.ug/media/',
  pythonApi: 'http://localhost:5000/api/v1.0/',
  // pythonApi: 'https://appliedprinciplesenv.com/api/v1.0/',
  // pythonApi: 'http://192.168.43.115:8000/api/v1.0/',
  backOffice: 'http://127.0.0.1:4200'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
