<main class="fadeInUp _delay4ms">

    <app-page-breadcrumb [marginTop]="'-15px'" [title]="'News'"></app-page-breadcrumb>

    <article class="main-wrapper">
        
        <section *ngIf="selected">
            
            <h1 [innerHTML]="selected.title"></h1>
    
            <!-- <div>
                Nov 20
            </div> -->
    
            <figure>
                <img [src]="selected.image" alt="">
            </figure>
    
            <div [innerHTML]="selected.content"></div>

        </section>
    </article>
    
    <article class="more-news">
    
        <section *ngIf="news.length">

            <h2>More News</h2>
            
            <div *ngFor="let item of news" (click)="onRead(item)">
                <figure>
                    <img [src]="item.image" alt="">
                </figure>
        
                <h3 [innerHTML]="item.title | textShortner : otherStoryShortner.title"></h3>
        
                <p [innerHTML]="item.content | textShortner : otherStoryShortner.summary"></p>
            </div>                
            
        </section>
    
    </article>    

</main>