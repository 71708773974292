<!-- <router-outlet></router-outlet> -->
<main id="main-content-wrapper" (scroll)="onScroll()">

    <app-header></app-header>
    
    <!-- <app-quick-links></app-quick-links> -->

    <section class="dynamic">

        <router-outlet></router-outlet>
        
    </section>
    
    <app-footer></app-footer>
    
</main>