import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  onScroll() {
    const homeEle = document.getElementById('main-content-wrapper') as HTMLElement;

    const st = window.pageYOffset || homeEle.scrollTop;

    // // this.scrollingUp = st < this.previousScrollPosition ? true : st < 200 ? true : false;
    // this.scrollingUp = st < this.previousScrollPosition ? true : false;

    // this.service.scrollingUp.next({
    //   state: this.scrollingUp,
    //   position: st
    // });

    // this.previousScrollPosition = st <= 0 ? 0 : st;
  }  
}
