<main class="fadeInUp _delay4ms">

    <app-page-breadcrumb [marginTop]="'-15px'" [title]="'Updates'"></app-page-breadcrumb>

    <article class="main-wrapper">
        
        <!-- <section> -->
            
            <section 
                *ngFor="let item of updates" 
                [ngClass]="{'selected-content': selected === item}"
                (click)="selected = item">
                <!-- <figure *ngIf="selected === item">
                    <img [src]="item.image" alt="">
                </figure> -->

                <div class="content-wrapper" >
                    <figure>
                        <img [src]="item.image" alt="">
                    </figure>
            
                    <!-- <div class="content"> -->
                        <h3 [innerHTML]="item.title"></h3>
                
                        <!-- <p [innerHTML]="item.content | textShortner : (selected === item ? 10000000 : 400)"></p> -->
                        <p [innerHTML]="item.content"></p>
                    <!-- </div> -->

                </div>


            </section>  

            <div class="button-wrapper" *ngIf="loadMore">
                <button 
                    mat-raised-button 
                    (click)="onLoadMore()" 
                    [disabled]="processing"
                    [ngClass]="{'inline-processing-btn': processing}" >
                    <div class="btn-label">
                        <app-inline-mat-spinner *ngIf="processing"></app-inline-mat-spinner>                    
                        <span>Load More</span>
                    </div>
                </button>
            </div>

        <!-- </section> -->
    </article>
    
</main>