import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ReadAllNewsComponent } from '../components/read-all-news/read-all-news.component';
import { ReadAllUpdatesComponent } from '../components/read-all-updates/read-all-updates.component';
import { RegisterComplaintComponent } from '../pages/data-subjects/register-complaint/register-complaint.component';
import { VerifyCertificateComponent } from '../pages/verify-certificate/verify-certificate.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule)
  },  
  {
    path: 'data-subjects',
    loadChildren: () => import('../pages/data-subjects/data-subjects.module').then(m => m.DataSubjectsModule)
  },  
  {
    path: 'information-center',
    loadChildren: () => import('../pages/information-center/information-center.module').then(m => m.InformationCenterModule)
  },      
  {
    path: 'register',
    loadChildren: () => import('../pages/create-account/create-account.module').then(m => m.CreateAccountModule)
  },   
  {
    path: 'about-us',
    loadChildren: () => import('../pages/about-us/about-us.module').then(m => m.AboutUsModule)
  },    
  {
    path: 'verify-certificate/:certificate-number',
    component: VerifyCertificateComponent
  },   
  {
    path: 'file-complaint',
    component: RegisterComplaintComponent
  },    
  {
    path: 'news/:slug',
    component: ReadAllNewsComponent
  },    
  {
    path: 'updates/:slug',
    component: ReadAllUpdatesComponent
  },    
  {
    path: 'search-register',
    loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule)
  },   
  {
    path: '**',
    redirectTo: '/home',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
