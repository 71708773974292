import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-message-dialog',
  templateUrl: './notification-message-dialog.component.html',
  styleUrls: ['./notification-message-dialog.component.scss']
})
export class NotificationMessageDialogComponent implements OnInit {

  processing = false;
  httpSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, status: boolean },
    private dialogRef: MatDialogRef<NotificationMessageDialogComponent>,
  ) { }

  ngOnInit(): void { }

  onCloseDialog(): void {
    this.dialogRef.close();
  }  

}
