import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { SuccessfulMessageDialogComponent } from 'src/app/dialogs/successful-message-dialog/successful-message-dialog.component';
import { NotificationMessageDialogComponent } from 'src/app/dialogs/notification-message-dialog/notification-message-dialog.component';
import { ApiEndpointsService } from 'src/app/services/api-endpoints.service';
import { ApiPayload, CompanyRegistrationModel, ComplaintModel, ComplaintReason, ComplaintRemedialAction, Country, DataCategory,DataSubCategory, FileUpload, Organisation } from 'src/app/services/api.model';
import { ApiService } from 'src/app/services/api.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MatStepper } from '@angular/material/stepper';
import { OrgUploadComponent } from 'src/app/dialogs/org-upload/org-upload.component';
import { ComplaintLetterDialogComponent } from 'src/app/dialogs/complaint-letter-dialog/complaint-letter-dialog.component';
import { UploadRepresentationComponent } from 'src/app/dialogs/upload-representation/upload-representation.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ViewPdfDocumentBottomsheetComponent } from 'src/app/bottomsheets/view-pdf-document-bottomsheet/view-pdf-document-bottomsheet.component';
@Component({
  selector: 'app-register-complaint',
  templateUrl: './register-complaint.component.html',
  styleUrls: ['./register-complaint.component.scss']
})
export class RegisterComplaintComponent implements OnInit, AfterViewInit, OnDestroy {

  processing = false;
  bottomsheetRef;
  selectedIndex = 0;
  isLinear = true;
  dialogRef;
  today = new Date();
  httpSub: Subscription;
  organisations: Organisation[];
  OrganisationID = 0;
  RegTrackingNo = null;
  OrganisationComplainedFromID = 0;
  filteredOptions: Observable<Organisation[]>;
  selectedDataCategory: DataCategory[] = [];
  selectedDataSubCategory: DataSubCategory[] = [];
  dataCategories: DataCategory[];
  complaintReasons: ComplaintReason[];
  selectedComplaintReasons: ComplaintReason[] = [];
  selComplaintReasons: ComplaintReason[] = [];
  remedialActions: ComplaintRemedialAction[];
  SingleComplaint: ComplaintModel | null;
  selectedRemedialAction: ComplaintRemedialAction[] = [];
  selected = false;
  countries: Country[];
  ShowLetter = false;
  evidenceDocument: FileUpload | null;
  RepresentationEvidence: FileUpload | null;
  company: CompanyRegistrationModel;
  
  dataCategory = new FormControl();
  organisation = new FormControl();
  formComplaintStatus: FormGroup;
  formPersonMakingComplaint: FormGroup;
  formDetailsOfComplaint: FormGroup;
  formRemedialAction: FormGroup;
  formPersonalDataAffected: FormGroup;
  success_message = false;
  ComplaintTrackingNo;
  updateComplaintStatus = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: ApiService,
    private endpoints: ApiEndpointsService,
    private changeDetector: ChangeDetectorRef,
    private bottomsheet: MatBottomSheet,
    private http: HttpClient,
    private dialog: MatDialog
  ) {
    this.service.updatePageTitle(['File A Complaint']);     
  }

  ngOnInit(): void {
    this.formComplaintStatus = this.formBuilder.group({
      Option: new FormControl('', [Validators.required]),
      OrganisationComplainedTo: new FormControl('', [Validators.required]),
      OtherOrganisationComplainedTo: new FormControl(''), 
      OtherOrganisationComplainedToAddress: new FormControl(''), 
      IsCustomerToOrganisation: new FormControl('',[Validators.required]),
      ComplaintTrackingNo: new FormControl(''),
      ContactFirstName: new FormControl(''),
      ContactLastName: new FormControl(''),
      ContactEmailAddress: new FormControl('', [Validators.email]),
      PhoneNumberContact: new FormControl(''),
      ContactPhoneNumber: new FormControl('', [Validators.pattern(/^[0-9]+$/)])
    });

    this.formPersonMakingComplaint = this.formBuilder.group({
      FirstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)]),
      LastName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)]),
      PhysicalAddress: new FormControl('', [Validators.required]),
      PhoneNumberCode: new FormControl('', [Validators.required]),
      PhoneNumber: new FormControl('', [Validators.required,Validators.pattern(/^[0-9 ]+$/)]),     
      EmailAddress: new FormControl('', [Validators.email]),
      // added a new FormControl name
      ComplaintCategory: new FormControl('', [Validators.required]),
      RepFirstName: new FormControl(''),
      Gender: new FormControl('', [Validators.required]),
      Age: new FormControl('', [Validators.required])    
    }); 

    this.formPersonalDataAffected = this.formBuilder.group({
      PersonalDataAffected: new FormControl(''),
    });     
    
    this.formDetailsOfComplaint = this.formBuilder.group({
      FullDetailsOfComplaint: new FormControl('', [Validators.required]),
      Form: new FormControl('', [Validators.required]),
    });     

    this.Listeners();
  }

  ngAfterViewInit(): void {
    this.httpSub = this.fetchMultiple()
    .pipe(catchError(this.service.handleError))
    .subscribe((responseList) => {
      this.countries = responseList[0];

      this.formComplaintStatus.get('PhoneNumberContact').patchValue('256');

      this.formPersonMakingComplaint.get('PhoneNumberCode').patchValue('256');

      this.dataCategories = responseList[1];  

      this.organisations = responseList[2]; 
      console.log(this.organisations);

      this.filteredOptions = this.formComplaintStatus.get('OrganisationComplainedTo').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );      

   
      this.processing = false;
    }, (error) => {
      this.processing = false;
      this.service.determineErrorResponse(error);
    });  
  }

  private Listeners(): void {
    // STEP ONE
    this.formComplaintStatus.get('OrganisationComplainedTo').valueChanges.subscribe((value) => {
      if (value === 'Other') {
        this.formComplaintStatus.get('OtherOrganisationComplainedTo').setValidators([Validators.required]);     
      } else {
  
        this.formComplaintStatus.get('OtherOrganisationComplainedTo').clearValidators();
        this.formComplaintStatus.get('OtherOrganisationComplainedTo').reset();
      }
      this.formComplaintStatus.controls['OtherOrganisationComplainedTo'].updateValueAndValidity();    
    });

    this.formComplaintStatus.get('Option').valueChanges.subscribe((value) => {
      if (value === 'Yes') {
        this.formComplaintStatus.get('ContactFirstName').setValidators([Validators.pattern(/^[a-zA-Z]+$/)]);     
        this.formComplaintStatus.get('ContactLastName').setValidators([Validators.pattern(/^[a-zA-Z]+$/)]);     
        this.formComplaintStatus.get('ContactEmailAddress').setValidators([Validators.email]);
        this.formComplaintStatus.get('ContactPhoneNumber').setValidators([Validators.pattern(/^[0-9 ]+$/)]);     
      } else {
        this.formComplaintStatus.get('ContactFirstName').clearValidators();
        this.formComplaintStatus.get('ContactLastName').clearValidators();
        this.formComplaintStatus.get('ContactEmailAddress').clearValidators();
        this.formComplaintStatus.get('ContactPhoneNumber').clearValidators();
        this.formComplaintStatus.get('ContactFirstName').reset();
        this.formComplaintStatus.get('ContactLastName').reset();
        this.formComplaintStatus.get('ContactEmailAddress').reset();
        this.formComplaintStatus.get('ContactPhoneNumber').reset();

        this.onRemoveDocument();

        this.dialogRef = this.dialog.open(NotificationMessageDialogComponent, {
          panelClass: ['notification-message-dialog', 'dialogs'],
          disableClose: true,
          height: '200px',
          width: '800px',
          data: {
            title: 'PLEASE NOTE:',
            message: 'The Law requires that you first deliver the complaint to the organisation and get back to us if your complaint has not been resolved within 30 days. To facilitate this process, the system will generate a letter for your action.'
          }
        });
      }
      this.formComplaintStatus.controls['ContactFirstName'].updateValueAndValidity();    
      this.formComplaintStatus.controls['ContactLastName'].updateValueAndValidity();    
      this.formComplaintStatus.controls['ContactEmailAddress'].updateValueAndValidity();
      this.formComplaintStatus.controls['ContactPhoneNumber'].updateValueAndValidity();    
    });

    //STEP TWO
    this.formPersonMakingComplaint.get('ComplaintCategory').valueChanges.subscribe((value) => {
      if (value === 'Representative') {
        this.formPersonMakingComplaint.get('RepFirstName').setValidators([
          Validators.required, 
          Validators.maxLength(35), 
          Validators.pattern(/^[a-zA-Z ]+$/)
        ]);      
      } else {
        this.formPersonMakingComplaint.get('RepFirstName').clearValidators();
        this.formPersonMakingComplaint.get('RepFirstName').reset();
      }

      this.formPersonMakingComplaint.controls['RepFirstName'].updateValueAndValidity();
    });
    
    // this.formPersonMakingComplaint.get('OrganisationComplainedFrom').valueChanges.subscribe((value) => {
    //   if (value === 'Other') {
    //     this.formPersonMakingComplaint.get('OrganisationComplained').setValidators([Validators.required]);     
    //   } else {
  
    //     this.formPersonMakingComplaint.get('OrganisationComplained').clearValidators();
    //     this.formPersonMakingComplaint.get('OrganisationComplained').reset();
    //   }
    //   this.formPersonMakingComplaint.controls['OrganisationComplained'].updateValueAndValidity();    
    // });

    // STEP THREE
    this.dataCategory.valueChanges.subscribe((value) => {
      const values: string[] = [...value];
      this.selectedDataCategory.length = 0;
      this.dataCategories.filter((category) => {
        values.filter((item) => {
          if (category.DataCategory === item) {
            this.selectedDataCategory.push(category);
            this.changeDetector.detectChanges();
          }
        });
      });
    });     
  }

  getFirstNameErrorMessage(): string {
    return this.formPersonMakingComplaint.get('FirstName').hasError('required') 
    || this.formComplaintStatus.get('ContactFirstName').hasError('required') ? 'Please enter a value' :
    this.formPersonMakingComplaint.get('FirstName').hasError('pattern') 
    || this.formComplaintStatus.get('ContactFirstName').hasError('pattern') ? 'Please enter a correct name' : '';
  }  

  getLastNameErrorMessage(): string {
    return this.formPersonMakingComplaint.get('LastName').hasError('required') 
    || this.formComplaintStatus.get('ContactLastName').hasError('required') ? 'Please enter a value' :
    this.formPersonMakingComplaint.get('LastName').hasError('pattern')
    || this.formComplaintStatus.get('ContactLastName').hasError('pattern') ? 'Please enter a correct name' : '';
  }

  getComplaintTrackingError(): string {
    return 'Invalid complaint tacking number';
  }
  
  getLocationErrorMessage(): string {
    return this.formPersonMakingComplaint.get('Location').hasError('required') ? 'Please enter a location' : '';
  }
  
  getTelephoneNumberErrorMessage(): string {
    return this.formPersonMakingComplaint.get('PhoneNumber').hasError('required') ? 'Please enter a value' : 
    this.formPersonMakingComplaint.get('PhoneNumber').hasError('pattern') ? 'Wrong Phone number' : ''
  }

  getEmailAddressErrorMessage(): string {
    return this.formComplaintStatus.get('ContactEmailAddress').hasError('required') ? 'Please enter a value' :
    this.formPersonMakingComplaint.get('EmailAddress').hasError('email') 
    || this.formComplaintStatus.get('ContactEmailAddress').hasError('email')  ? 'Wrong Email Address.' : '';
  }

  getFullDetailsOfComplaintErrorMessage(): string {
    return this.formDetailsOfComplaint.get('FullDetailsOfComplaint').hasError('required') ? 'Please write the full detail of the complaint' : '';
  }

  getNameOfRepresentativeErrorMessage(): string {
    return this.formPersonMakingComplaint.get('RepFirstName').hasError('required') ? 'Please enter a value' :
    this.formPersonMakingComplaint.get('RepFirstName').hasError('maxlength') ? 'You have reached the maximum' : 
    this.formPersonMakingComplaint.get('RepFirstName').hasError('pattern') ? 'Please enter a correct name' : '';
  }

  getSelectErrorMessage(): string {
    return this.formComplaintStatus.get('OrganisationComplainedTo').hasError('required')
    || this.formPersonMakingComplaint.get('Gender').hasError('required')
    || this.formPersonMakingComplaint.get('Age').hasError('required')
    ? 'Please choose a value' : '';
  }

  private _filter(value: string): Organisation[] {
    const filterValue = value.toLowerCase();

    return this.organisations.filter(option => option.OrganisationName.toLowerCase().includes(filterValue));
  }

  onChangeSubCategory(event: MatCheckboxChange, SubCategory: DataSubCategory): void {
    if (event.checked) {
      this.selectedDataSubCategory.push(SubCategory);
    } else {
      this.selectedDataSubCategory.splice(this.selectedDataSubCategory.indexOf(SubCategory), 1);
    }
  }

  onSelectComplaintType(form: number): void {
    this.selectedComplaintReasons.length = 0;

    setTimeout(() => {
      if (form === 9) {
        this.httpSub = this.fetchComplaintReasons(form)
        .pipe(catchError(this.service.handleError))
        .subscribe((responseList) => {
          this.selectedComplaintReasons = responseList[0];
          
          if (this.SingleComplaint.FormID === 9){
            this.mapFieldsForSelectedReasons();
          }
        }, (error) => {
          this.processing = false;
          this.service.determineErrorResponse(error);
        });  
      } else if (form === 11) {
        this.httpSub = this.fetchComplaintReasons(form)
        .pipe(catchError(this.service.handleError))
        .subscribe((responseList) => {
          this.selectedComplaintReasons = responseList[0];

          if (this.SingleComplaint.FormID === 9){
            this.mapFieldsForSelectedReasons();
          }
        }, (error) => {
          this.processing = false;
          this.service.determineErrorResponse(error);
        });  
      }
    });
  }
  
  onChangeComplaintReason(event: MatCheckboxChange, reason: any): void {
    if (event.checked) {
      this.selComplaintReasons.push(reason);
    } else {
      this.selComplaintReasons.splice(this.selComplaintReasons.indexOf(reason), 1);
    }
  }

  onChangeRemedialAction(event: MatCheckboxChange, action: ComplaintRemedialAction): void {
    if (event.checked) {
      this.selectedRemedialAction.push(action);
    } else {
      this.selectedRemedialAction.splice(this.selectedRemedialAction.indexOf(action), 1);
    }
  }

  private fetchMultiple(): Observable<any[]> {
    this.processing = true;
    const reqCountries = this.http.get<ApiPayload>(this.endpoints.countries)
    const reqDataSubcategories = this.http.get<ApiPayload>(this.endpoints.dataSubcategories)
    const reqGetOrganisations = this.http.get<ApiPayload>(this.endpoints.getOrganisations)

    return forkJoin([reqCountries, reqDataSubcategories, reqGetOrganisations])
  }

  private fetchComplaintReasons(FormID): Observable<any[]> {
    const reasons =  this.http.get<ApiPayload>(this.endpoints.getComplaintReasons+'?FormID='+FormID)
 
    return forkJoin([reasons]);
  }

  // private clearFormData():void {
  //   this.dataCategory.reset();
  //   this.organisation.reset();
  //   this.HaveComplainedAlready.reset();
  //   this.formPersonMakingComplaint.reset();
  //   this.formDetailsOfComplaint.reset();
  //   this.OrganisationID = null;
  //   this.selectedDataSubCategory.length = 0;
  //   this.selectedRemedialAction.length = 0;
  // }

  onDownload(): void {
    this.processing = true;

    var data = document.getElementById('contentToConvert');
  
    html2canvas(data).then(canvas => {
        // Few necessary setting options
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save('Complaint-Letter.pdf'); 
    });

    this.onSubmit();

    this.processing =false;

  }

  onUploadAttachments(): void {
    this.dialogRef = this.dialog.open(OrgUploadComponent, {
      panelClass: ['organisation-upload-dialog', 'dialogs'],
      disableClose: true,
      data: {
        multiple: false,
        maxSize: 3
      }
    });

    this.dialogRef.afterClosed().subscribe((result: { status: boolean, row: FileUpload }) => {
      // console.log('row:', result.row);

      if (result.status) {
        this.evidenceDocument = result.row;
        console.log(this.evidenceDocument);
      }
    });     
  }

  onUploadRepresentationAttachments(): void {
    this.dialogRef = this.dialog.open(UploadRepresentationComponent, {
      panelClass: ['organisation-upload-dialog', 'dialogs'],
      disableClose: true,
      data: {
        multiple: false,
        maxSize: 3
      }
    });

    this.dialogRef.afterClosed().subscribe((result: { status: boolean, row: FileUpload }) => {
      // console.log('row:', result.row);

      if (result.status) {
        this.RepresentationEvidence = result.row;
      }
    });     
  }
  
  onRemoveDocument(): void {
    this.evidenceDocument = null;
  }

  onRemoveReprsentationDocument(): void {
    this.RepresentationEvidence = null;
  }

  private getFormData(): any {
    console.log(this.formComplaintStatus.get('OtherOrganisationComplainedToAddress').value);
    const data = {
      FormID: parseInt(this.formDetailsOfComplaint.get('Form').value),
      ComplaintSourceID: 2,      
      FirstName: this.formPersonMakingComplaint.get('FirstName').value,
      LastName: this.formPersonMakingComplaint.get('LastName').value,
      PhoneNumber: this.formPersonMakingComplaint.get('PhoneNumberCode').value + (this.formPersonMakingComplaint.get('PhoneNumber').value).replaceAll(' ', ''),
      EmailAddress: this.formPersonMakingComplaint.get('EmailAddress').value,
      PhysicalAddress: this.formPersonMakingComplaint.get('PhysicalAddress').value,
      PersonalDataAffected: this.selectedDataSubCategory,
      ReasonsForComplaint: this.selComplaintReasons,
      OrganisationComplainedTo: this.OrganisationID,
      OtherOrganisationComplainedTo: this.formComplaintStatus.get('OtherOrganisationComplainedTo').value ? this.formComplaintStatus.get('OtherOrganisationComplainedTo').value : '',
      OtherOrganisationComplainedToAddress: this.formComplaintStatus.get('OtherOrganisationComplainedToAddress').value ? this.formComplaintStatus.get('OtherOrganisationComplainedToAddress').value : '',
      HaveComplainedToOrganisationAlready: this.formComplaintStatus.get('Option').value,
      FullDetailsOfComplaint: this.formDetailsOfComplaint.get('FullDetailsOfComplaint').value,
      IsCustomerToOrganisation: this.formComplaintStatus.get('IsCustomerToOrganisation').value,
      ComplainerCategory: this.formPersonMakingComplaint.get('ComplaintCategory').value,
      ContactFirstName: this.formComplaintStatus.get('ContactFirstName').value ? this.formComplaintStatus.get('ContactFirstName').value : '',
      ContactLastName: this.formComplaintStatus.get('ContactLastName').value ? this.formComplaintStatus.get('ContactLastName').value : '',
      ContactEmailAddress: this.formComplaintStatus.get('ContactEmailAddress').value ? this.formComplaintStatus.get('ContactEmailAddress').value : '',
      //ContactPhoneNumber: this.formComplaintStatus.get('PhoneNumberContact').value + (this.formComplaintStatus.get('ContactPhoneNumber').value) ? this.formComplaintStatus.get('ContactPhoneNumber').value : '',
      ContactPhoneNumber: '',
      ComplaintLetter: '',
      ComplaintTrackingNo: this.formComplaintStatus.get('ComplaintTrackingNo').value ? this.formComplaintStatus.get('ComplaintTrackingNo').value : '',
      AgeGroup: this.formPersonMakingComplaint.get('Age').value ? this.formPersonMakingComplaint.get('Age').value : '',
      Gender: this.formPersonMakingComplaint.get('Gender').value,
      ComplaintEvidence : this.evidenceDocument ? this.evidenceDocument.Base64 : '',
      Representative: this.formPersonMakingComplaint.get('RepFirstName').value ? this.formPersonMakingComplaint.get('RepFirstName').value : '',
      RepresentativeEvidence: '',
    }

    return data;
  }  

  onPreviewLetter(download: boolean): void {
    const data = this.getFormData();

    if (this.company || !this.OrganisationID) {

      this.dialogRef = this.dialog.open(ComplaintLetterDialogComponent, {
        panelClass: ['complaint-letter-dialog', 'dialogs'],
        disableClose: true,
        data: {
          ComplaintForm: data,
          Organisation: this.company,
          Date: new Date(),
          autoDownload: download
        }
      }); 

      if(download){
        this.success_message = true;
        this.onSubmit();
      }
    } else {
      this.processing = true;

      this.httpSub = this.http.get<CompanyRegistrationModel>(this.endpoints.getOrganisationSingle, {
        params: { RegTrackingNo: this.RegTrackingNo }
      })
      .pipe(catchError(this.service.handleError))
      .subscribe((response) => {
        this.company = response;

        this.dialogRef = this.dialog.open(ComplaintLetterDialogComponent, {
          panelClass: ['complaint-letter-dialog', 'dialogs'],
          disableClose: true,
          data: {
            ComplaintForm: data,
            Organisation: this.company,
            Date: new Date(),
            autoDownload: download,
          }
        });  
      
        this.processing = false;
      }, (error) => {
        this.processing = false;
        this.service.determineErrorResponse(error);
      }); 

      if(download){
        this.success_message = true;
        this.onSubmit();
      }

    }
  }

  onSubmit(): void {
    this.processing = true;
    console.log('data:', this.getFormData());

    this.formComplaintStatus.disable();
    this.formPersonMakingComplaint.disable();
    this.formDetailsOfComplaint.disable();

    this.httpSub = this.http.post<ApiPayload>(this.endpoints.complaints, this.getFormData())
    .pipe(catchError(this.service.handleError))
    .subscribe((response) => {
      
      console.log('response:', response);

      this.processing = false;

      if(this.success_message){

        this.dialogRef = this.dialog.open(SuccessfulMessageDialogComponent, {
          panelClass: ['successful-message-dialog', 'dialogs'],
          disableClose: true,
          data: {
            title: 'Complaint Letter Downloaded Successfully',
            message: 'Please deliver the complaint letter to the organisation'
          }
        });
        this.success_message = false;
      }
      else{
        this.dialogRef = this.dialog.open(SuccessfulMessageDialogComponent, {
          panelClass: ['successful-message-dialog', 'dialogs'],
          disableClose: true,
          data: {
            title: 'Complaint submitted Successfully',
            message: response.message
          }
        });
      }

     

      this.dialogRef.afterClosed().subscribe(() => {
        location.reload();
      });   
    }, (error) => {
      this.processing = false;
      this.formComplaintStatus.enable();
      this.formPersonMakingComplaint.enable();
      this.formDetailsOfComplaint.enable();
      this.service.determineErrorResponse(error);
    });     
  }

  onUpdateComplaint(){
    this.processing = true;

    console.log('data:', this.getFormData());

    this.formComplaintStatus.disable();
    this.formPersonMakingComplaint.disable();
    this.formDetailsOfComplaint.disable();

    this.httpSub = this.http.post<ApiPayload>(this.endpoints.updateComplaint, this.getFormData())
    .pipe(catchError(this.service.handleError))
    .subscribe((response) => {
      
      console.log('response:', response);

      this.processing = false;

      
        this.dialogRef = this.dialog.open(SuccessfulMessageDialogComponent, {
          panelClass: ['successful-message-dialog', 'dialogs'],
          disableClose: true,
          data: {
            title: 'Complaint submitted Successfully',
            message: response.message
          }
        });

     

      this.dialogRef.afterClosed().subscribe(() => {
        location.reload();
      });   
    }, (error) => {
      this.processing = false;
      this.formComplaintStatus.enable();
      this.formPersonMakingComplaint.enable();
      this.formDetailsOfComplaint.enable();
      this.service.determineErrorResponse(error);
    });     

  }

  onChangeComplaintTrackingNo(field){
    this.ComplaintTrackingNo = field.target.value;

    this.httpSub = this.fetchSingleComplaint(this.ComplaintTrackingNo)
    .pipe(catchError(this.service.handleError))
    .subscribe((responseList) => {
      
      if(responseList[0]['ComplaintID'] === undefined){
        this.updateComplaintStatus = false;    
        this.formComplaintStatus.controls['ComplaintTrackingNo'].setErrors({'incorrect': true});
        this.processing = false;
      }  else{
        this.SingleComplaint = responseList[0];
        this.formComplaintStatus.controls['ComplaintTrackingNo'].setErrors(null);
        this.mapFields();
        this.updateComplaintStatus = true;
      }     
      
      this.changeDetector.detectChanges();
    }, (error) => {
      this.processing = false;
      this.service.determineErrorResponse(error);
    });  
  }

  private fetchSingleComplaint(ComplaintTrackingNo):Observable<any[]> {
    this.processing = true;

    const complaint = this.http.get<ApiPayload>(this.endpoints.getSingleComplaint, {
      params: { ComplaintTrackingNo: ComplaintTrackingNo }
    })

    return forkJoin([complaint])
  }

  private mapFields(){
    // console.log(this.SingleComplaint);
    this.processing = false;

    // STEP ONE
    this.formComplaintStatus.get('OrganisationComplainedTo').setValue(this.SingleComplaint.OrganisationComplainedToName);
    this.OrganisationID = this.SingleComplaint.OrganisationComplainedTo;
    this.formComplaintStatus.get('OtherOrganisationComplainedTo').setValue(this.SingleComplaint.OtherOrganisationComplainedTo);
    this.formComplaintStatus.get('ContactFirstName').setValue(this.SingleComplaint.ContactPersonFirstName);
    this.formComplaintStatus.get('ContactLastName').setValue(this.SingleComplaint.ContactPersonLastName);
    this.formComplaintStatus.get('ContactEmailAddress').setValue(this.SingleComplaint.ContactPersonEmailAddress);
    this.formComplaintStatus.get('PhoneNumberContact').patchValue(this.SingleComplaint.ContactPersonPhoneNumber.substring(0, 3));
    this.formComplaintStatus.get('ContactPhoneNumber').patchValue(this.SingleComplaint.ContactPersonPhoneNumber.substring(3, 13));

    // STEP TWO
    this.formPersonMakingComplaint.get('FirstName').setValue(this.SingleComplaint.FirstName);
    this.formPersonMakingComplaint.get('LastName').setValue(this.SingleComplaint.LastName);
    this.formPersonMakingComplaint.get('PhoneNumber').setValue(this.SingleComplaint.PhoneNumber.substring(3));
    this.formPersonMakingComplaint.get('EmailAddress').setValue(this.SingleComplaint.EmailAddress);
    this.formPersonMakingComplaint.get('PhysicalAddress').setValue(this.SingleComplaint.PhysicalAddress);
    this.formPersonMakingComplaint.get('Gender').setValue(this.SingleComplaint.Gender);
    this.formPersonMakingComplaint.get('Age').setValue(this.SingleComplaint.AgeGroup);
    this.formPersonMakingComplaint.get('ComplaintCategory').setValue(this.SingleComplaint.ComplainerCategory);
    this.formPersonMakingComplaint.get('RepFirstName').setValue(this.SingleComplaint.Representative);

    // STEP THREE
    if(this.SingleComplaint.PersonalDataAffected.length > 0){
      const personalDataCollected: DataSubCategory[] = this.SingleComplaint.PersonalDataAffected;

      this.dataCategories.filter((category) => {
        category.SubCategories.filter((subCat) => {
          personalDataCollected.filter((selectedSubCat) => {
            if (subCat.DataSubCategoryID === selectedSubCat.DataSubCategoryID) {
              subCat.IsChecked = true;
              this.selectedDataSubCategory.push(subCat);
            }
          });
        });
      });      
    }

    // STEP FOUR
    this.formDetailsOfComplaint.get('Form').setValue(this.SingleComplaint.FormID);
    this.onSelectComplaintType(this.SingleComplaint.FormID);
    this.formDetailsOfComplaint.get('FullDetailsOfComplaint').setValue(this.SingleComplaint.FullDetailsOfComplaint);

    this.evidenceDocument = { 
      Document: 'Document', 
      Name: 'Evidence.pdf', 
      Size: '', 
      Base64: this.SingleComplaint.EvidenceOfComplaint
    };
    this.changeDetector.markForCheck(); 
    this.changeDetector.detectChanges();     
  }

  private mapFieldsForSelectedReasons(): void {
    if(this.SingleComplaint.ReasonsForComplaint.length > 0){
      const complaintReason: ComplaintReason[] = this.SingleComplaint.ReasonsForComplaint;

      complaintReason.filter((selectedReason) => {
        this.selectedComplaintReasons.filter((reason) => {
          if (selectedReason.ComplaintReasonID === reason.ComplaintReasonID) {
            reason.IsChecked = true;
            this.selComplaintReasons.push(reason);
          }
        });     
      });
    }

    this.changeDetector.detectChanges();    
  }

  onReadDocument(document: FileUpload): void {
    this.bottomsheetRef = this.bottomsheet.open(ViewPdfDocumentBottomsheetComponent, {
        disableClose: true,
        panelClass: ['view-pdf-document-bottomsheet', 'bottomsheets'],
        data: {
          url: document.Base64,
          title: document.Name
        }
    });      
}

  ngOnDestroy(): void {
    if (this.httpSub) { this.httpSub.unsubscribe(); }
    if (this.dialogRef) { this.dialogRef.close(); }
    if (this.bottomsheetRef) { this.bottomsheetRef.dismiss(); }
  } 
}

