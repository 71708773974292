import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  processing = false;
  httpSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, status: boolean },
    private dialogRef: MatDialogRef<TermsConditionsComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
