import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './modules/app-routing.module';
import { SharedModule } from './modules/shared-module.module';
import { MaterialModule } from './modules/material.module';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { ApiService } from './services/api.service';
import { VerifyCertificateComponent } from './pages/verify-certificate/verify-certificate.component';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { UploadRepresentationComponent } from './dialogs/upload-representation/upload-representation.component';
import { NotificationMessageDialogComponent } from './dialogs/notification-message-dialog/notification-message-dialog.component';
import { TermsConditionsComponent } from './dialogs/terms-and-conditions/terms-conditions/terms-conditions.component';
import { PrivacyNoticeComponent } from './dialogs/notice/privacy-notice/privacy-notice.component';


@NgModule({
  declarations: [
    AppComponent,
    VerifyCertificateComponent,
    HeaderComponent,
    FooterComponent,
    UploadRepresentationComponent,
    NotificationMessageDialogComponent,
    TermsConditionsComponent,
    PrivacyNoticeComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    DatePipe,
    ApiService,
    ApiEndpointsService,
  ],
  bootstrap: [AppComponent, VerifyCertificateComponent]
})
export class AppModule { }
