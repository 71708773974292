import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as html2pdf from 'html2pdf.js';
import { ApiService } from 'src/app/services/api.service';

interface Data {
  Organisation: string;
  RegNubmer: string;
  Director: string;
  DateOfIssue: Date;
}

@Component({
  selector: 'app-org-certificate-dialog',
  templateUrl: './org-certificate-dialog.component.html',
  styleUrls: ['./org-certificate-dialog.component.scss']
})
export class OrgCertificateDialogComponent implements OnInit {

  downloading = false;
  @ViewChild('pdfContainer') pdfContainer: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<OrgCertificateDialogComponent>,
    private service: ApiService,
    private datePipe: DatePipe,
  ) {
    this.data = {
      Organisation: 'Applied Principles Consulting',
      RegNubmer: 'REG/2323/FVD/43',
      Director: 'Enock Munyagwa',
      DateOfIssue: new Date(),
    }
  }

  ngOnInit(): void {
    this.onDownloadLetter();
    // this.createPdfTem();
  }
  
  onCloseWindow(): void {
    // this.dialogRef.close();
    // this.createPdfTem();
    this.onDownloadLetter();
  } 

  // public createPdfTem() {
  //   setTimeout(() => {
  //     console.log('this.pdfContainer.nativeElement:', this.pdfContainer.nativeElement);
  //     let status = this.pdfService.createPdf(this.pdfContainer.nativeElement, 'Certificate with new name');
  //     console.log('status:', status);
  //   }, 1000);
  // }  

  getDateSuperscript(): string {
    let day = parseInt(this.datePipe.transform(this.data.DateOfIssue, 'd'));

    if (day === 1) {
      return 'st';
    } else if (day === 2) {
      return 'nd';
    } else if (day === 3) {
      return 'rd';
    } else {
      return 'th';
    }
  }  

  onDownloadLetter(): void {
    this.downloading = true;

    setTimeout(() => {
      var element = document.getElementById('letterBodyContainer');

      var opt = {
        margin: 0,
        filename: this.data.Organisation + ' Certifiate.pdf',
        image: { 
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          scale: 2,
        },
        jsPDF: { 
          unit: 'in', 
          format: 'letter', 
          orientation: 'landscape'
        }
      };
   
      // New Promise-based usage:
      html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then((onFulfilled) => {
  
        setTimeout(() => {
          this.downloading = false;

          // if (this.data.download) {
          //   this.onCloseWindow();
          // }
          
          this.service.openSnackBar('Downloaded. Please check in your downloads.', 'success-lg');
        }, 1000);

      });          

    }, 1000);

    // setTimeout(() => {
    //   this.service.openSnackBar('Downloading. Please wait.', 'nuetral');

    //   const options = {
    //     filename: 'Certifiate.pdf',
    //     image: { type: 'jpeg' },
    //     html2canvas: {
    //       // height: 100,
    //     },
    //     jspdf: { orientation: 'l' },
    //     margin: 0,
    //   };
  
    //   const content: Element = document.getElementById('letterBodyContainer');
  
    //   html2pdf()
    //   .from(content)
    //   .set(options)
    //   .save()
    //   .then((onFulfilled) => {
  
    //     setTimeout(() => {
    //       this.downloading = false;

    //       // if (this.data.download) {
    //       //   this.onCloseWindow();
    //       // }
          
    //       this.service.openSnackBar('Downloaded. Please check in your downloads.', 'success-lg');
    //     }, 1000);

    //   }); 
    // }, 1000);
  }  
}

