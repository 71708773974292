<main class="fadeInUp _delay4ms">

    <app-page-breadcrumb [title]="'File Complaint'"></app-page-breadcrumb>
    <!-- <app-page-breadcrumb [title]="'Form 11 - Infringement / Violation of Act'"></app-page-breadcrumb> -->

    <mat-horizontal-stepper 
        [linear]="isLinear"
        [selectedIndex]="selectedIndex"
        labelPosition="bottom" #stepper>

        <mat-step [stepControl]="formComplaintStatus">
            <ng-template matStepLabel>
                <h3>Step One</h3>
            </ng-template>

            <form [formGroup]="formComplaintStatus" class="form-complaint-already-submitted personal-data-affected">
                <mat-form-field>
                    <mat-label>Which organisation is your Complaint Against ?<span class="required-field">required</span></mat-label>
                    <input type="text" #organisationInput placeholder="Pick one" formControlName="OrganisationComplainedTo" matInput [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.OrganisationName" (click)="OrganisationID = option.OrganisationID; RegTrackingNo = option.RegTrackingNo">
                            {{ option.OrganisationName }}
                        </mat-option>
                        <mat-option value="Other" (click)="OrganisationID = 0; RegTrackingNo = null">Other</mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="formComplaintStatus.get('OrganisationComplainedTo').invalid">{{ getSelectErrorMessage() }}</mat-error>
                </mat-form-field>           

                <mat-form-field *ngIf="organisationInput.value === 'Other'">
                    <mat-label>If Other, Please Specify <span class="required-field">required</span></mat-label>
                    <input appUppercase matInput formControlName="OtherOrganisationComplainedTo">                            
                </mat-form-field>

                <mat-form-field *ngIf="organisationInput.value === 'Other'">
                    <mat-label>Address of the organisation <span class="required-field">required</span></mat-label>
                    <input appUppercase matInput formControlName="OtherOrganisationComplainedToAddress">                            
                </mat-form-field>

                <!-- Added a radio button field for identifying the relationship of the person complaining to the organisation -->
                <div class="grouped-items">
                    <h3>Are you a customer to the organisation? <span class="required-field">required</span></h3>
    
                    <mat-radio-group color="primary" formControlName="IsCustomerToOrganisation">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                    </mat-radio-group>        
                </div>

                <div class="grouped-items">
                    <h3>Have you complained to the organisation ? <span class="required-field">required</span></h3>
    
                    <mat-radio-group color="primary" formControlName="Option">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                    </mat-radio-group>        
                </div>

                <section class="expand-section reduced-properties margin form" *ngIf="formComplaintStatus.get('Option').value === 'Yes'">

                    <h1 class="full-width heading">Provide A Complaint Tracking Number <span>(If Available)</span></h1>
                    
                    <mat-form-field>
                        <mat-label>Complaint Tracking Number</mat-label>
                        <input matInput maxlength="20" formControlName="ComplaintTrackingNo" (change)="onChangeComplaintTrackingNo($event)"> 
                        <mat-error *ngIf="formComplaintStatus.get('ComplaintTrackingNo').invalid">{{ getComplaintTrackingError() }}</mat-error>
                        <app-inline-mat-spinner matSuffix *ngIf="processing"></app-inline-mat-spinner>
                    </mat-form-field>
                </section>

                <section *ngIf="formComplaintStatus.get('Option').value === 'Yes'" class="expand-section reduced-properties table-wrapper margin documents">

                    <h1 class="full-width heading">Upload Evidence of the Complaint.</h1>            
                    
                    <section class="notice full-width">
                        <h2>
                            Notice
                        </h2>
            
                        <p>
                            We shall need a copy of letters or emails sent to the organisation and any responses from them.
                        </p>   
                    </section>                    

                    <div class="buttons">
                        <button 
                            mat-button
                            color="primary"
                            class="add-item-button"
                            (click)="onUploadAttachments()">
                            <div class="button-label">
                                <mat-icon>add</mat-icon>                            
                                <span> {{ evidenceDocument ? 'Replace Document' : 'Upload Document' }} </span>
                            </div>
                        </button>
    
                        <button 
                            mat-button
                            *ngIf="evidenceDocument?.Name"
                            color="primary"

                            class="remove-button"
                            (click)="onRemoveDocument()">
                            <div class="button-label">
                                <mat-icon>clear</mat-icon>
                                <span> Remove Document </span>
                            </div>
                        </button>
                    </div>

                    <div *ngIf="evidenceDocument?.Base64" class="uploaded-document" matTooltip="Open Document" (click)="onReadDocument(evidenceDocument)">
                        <img src="./assets/images/pdf.png" alt="">

                        <span>{{ evidenceDocument?.Name }} <span>  -  {{ evidenceDocument?.Size }} Mbs</span></span>
                    </div>
                </section>
    
                <h1 class="full-width heading" *ngIf="formComplaintStatus.get('Option').value === 'Yes'" >Details of Person spoken to at the Organisation</h1>

                <mat-form-field *ngIf="formComplaintStatus.get('Option').value === 'Yes'" >
                    <mat-label>First Name</mat-label>
                    <input matInput maxlength="30" formControlName="ContactFirstName">
                </mat-form-field>   

                <mat-form-field *ngIf="formComplaintStatus.get('Option').value === 'Yes'" >
                    <mat-label>Last Name</mat-label>
                    <input matInput maxlength="30" formControlName="ContactLastName">                            
                </mat-form-field>   

                <mat-form-field *ngIf="formComplaintStatus.get('Option').value === 'Yes'" >
                    <mat-label>Email Address</mat-label>
                    <input matInput maxlength="100" formControlName="ContactEmailAddress">                         
                    <mat-error *ngIf="formComplaintStatus.get('ContactEmailAddress').invalid">{{ getEmailAddressErrorMessage() }}</mat-error>                                            
                </mat-form-field>

                <div class="telephone-code-number-wrapper" *ngIf="formComplaintStatus.get('Option').value === 'Yes'">
                    <mat-form-field class="fadeInUp _delay2ms">
                        <mat-label>Pick one </mat-label>
                        <mat-select formControlName="PhoneNumberContact">
                            <mat-option selected *ngIf="!selected" value="256">+256</mat-option>
                            <!-- <mat-option selected *ngIf="!selected" value="256">+256 - Uganda</mat-option> -->
                            <mat-option *ngFor="let code of countries" [value]="code.PhoneCode" (click)="selected = true">
                                +{{ code.PhoneCode }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <mat-form-field class="fadeInUp _delay2ms" [hintLabel]="'Organisation Telephone Number.'"> -->
                    <mat-form-field class="fadeInUp _delay2ms">
                        <mat-label>Phone Number</mat-label>
                        <input type="text" maxlength="11" matInput appTelephoneFormaterDirective formControlName="ContactPhoneNumber" placeholder="Ex. 778 222444">
                    </mat-form-field> 
                </div>
            </form>

            <div class="button-wrapper single-button">
                       
                <!-- enabled the next button for No selection -->
                <button 
                    mat-button 
                    [disabled]="formComplaintStatus.invalid || (!evidenceDocument && formComplaintStatus.get('Option').value === 'Yes')"
                    class="next-btn" 
                    color="primary" 
                    matStepperNext>
                    Next
                </button>
            </div>            
        </mat-step>

        <mat-step [stepControl]="formPersonMakingComplaint">
            <ng-template matStepLabel>
                <h3>Person Making The <br /> Complaint</h3>
                <!-- <p>Details of the person making the complaint</p> -->
            </ng-template>

            <form [formGroup]="formPersonMakingComplaint">

                <mat-form-field>
                    <mat-label>First Name <span class="required-field">required</span></mat-label>
                    <input appRemoveSpaces matInput maxlength="15" formControlName="FirstName">
                    <mat-error *ngIf="formPersonMakingComplaint.get('FirstName').invalid">{{ getFirstNameErrorMessage() }}</mat-error>
                </mat-form-field>   

                <mat-form-field>
                    <mat-label>Last Name <span class="required-field">required</span></mat-label>
                    <input appRemoveSpaces matInput maxlength="15" formControlName="LastName">                            
                    <mat-error *ngIf="formPersonMakingComplaint.get('LastName').invalid">{{ getLastNameErrorMessage() }}</mat-error>
                </mat-form-field> 
                
                <div class="grouped-items">
                    <h3>Gender:<span class="required-field">required</span></h3>
    
                    <mat-radio-group color="primary" formControlName="Gender">
                        <mat-radio-button value="Male">Male</mat-radio-button>
                        <mat-radio-button value="Female">Female</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="formPersonMakingComplaint.get('Gender').invalid">{{ getSelectErrorMessage() }}</mat-error>
                </div>

                <mat-form-field>
                    <mat-label>Age Group:<span class="required-field">required</span></mat-label>
                    <mat-select formControlName="Age">
                        <mat-option value="18-24">18-24</mat-option>
                        <mat-option value="25-34">25-34</mat-option>
                        <mat-option value="35-44">35-44</mat-option>
                        <mat-option value="45-54">45-54</mat-option>
                        <mat-option value="55-64">55-64</mat-option>
                        <mat-option value="65-100">65-over</mat-option>
                    </mat-select>
                    <mat-error *ngIf="formPersonMakingComplaint.get('Age').invalid">{{ getSelectErrorMessage() }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Physical Address <span class="required-field">required</span></mat-label>
                    <input matInput maxlength="255"  formControlName="PhysicalAddress">  
                    <mat-error *ngIf="formPersonMakingComplaint.get('PhysicalAddress').invalid">{{ getLastNameErrorMessage() }}</mat-error>
                </mat-form-field>    

                <div class="telephone-code-number-wrapper">
                    <mat-form-field class="fadeInUp _delay2ms">
                        <mat-label>Pick one </mat-label>
                        <mat-select formControlName="PhoneNumberCode">
                            <mat-option selected *ngIf="!selected" value="256">+256</mat-option>
                            <!-- <mat-option selected *ngIf="!selected" value="256">+256 - Uganda</mat-option> -->
                            <mat-option *ngFor="let code of countries" [value]="code.PhoneCode" (click)="selected = true">
                                +{{ code.PhoneCode }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <mat-form-field class="fadeInUp _delay2ms" [hintLabel]="'Organisation Telephone Number.'"> -->
                    <mat-form-field class="fadeInUp _delay2ms">
                        <mat-label>Phone Number <span class="required-field">required</span></mat-label>
                        <input type="text" maxlength="11" matInput appTelephoneFormaterDirective formControlName="PhoneNumber" placeholder="Ex. 778 222444">
                        <mat-error *ngIf="formPersonMakingComplaint.get('PhoneNumber').invalid">{{ getTelephoneNumberErrorMessage() }}</mat-error>
                    </mat-form-field> 
                </div>  

                <mat-form-field>
                    <mat-label>Email Address</mat-label>
                    <input matInput maxlength="100" formControlName="EmailAddress">    
                    <mat-error *ngIf="formPersonMakingComplaint.get('EmailAddress').invalid">{{ getEmailAddressErrorMessage() }}</mat-error>                        
                </mat-form-field>

                <!-- Added another field to input the Organisation of the person complaining -->
                <div class="grouped-items">
                    <h3>Who are you complaining as:<span class="required-field">required</span></h3>
    
                    <mat-radio-group color="primary" formControlName="ComplaintCategory">
                        <mat-radio-button value="Individual">Individual</mat-radio-button>
                        <mat-radio-button value="Representative">Representative</mat-radio-button>
                    </mat-radio-group>
                       
                </div>

                <mat-form-field *ngIf="formPersonMakingComplaint.get('ComplaintCategory').value === 'Representative'">
                    <mat-label>Name of Representative <span class="required-field">required</span></mat-label>
                    <input matInput maxlength="35" formControlName="RepFirstName">    
                    <mat-error *ngIf="formPersonMakingComplaint.get('RepFirstName').invalid">{{ getNameOfRepresentativeErrorMessage() }}</mat-error>                        
                </mat-form-field>
                
            </form>

            <div class="button-wrapper">       
                <button mat-button class="back-btn" matStepperPrevious>Back</button>     

                <button 
                    [disabled]="formPersonMakingComplaint.invalid"
                    mat-button 
                    class="next-btn" 
                    color="primary" 
                    matStepperNext>
                    Next
                </button>
            </div>            
        </mat-step>

        <mat-step [stepControl]="selectedDataSubCategory">
            <ng-template matStepLabel>
                <h3>Personal Data Affected</h3>
            </ng-template>

            <form class="personal-data-affected" [formGroup]="formPersonalDataAffected" >

                <h1 class="full-width heading">Personal Data Affected</h1>
                
                <section *ngFor="let category of dataCategories; let i = index" class="expand-section categories">        
                    <div>
                        <h3 [innerHTML]="category.DataCategory"></h3>
                        <mat-icon matTooltipPosition="right"  [matTooltip]="category.DataDescription">info</mat-icon>
                    </div>

                    <mat-checkbox
                        formControlName = "PersonalDataAffected"
                        *ngFor="let SubCategory of category.SubCategories" 
                        [ngClass]="{'full-width': category.SubCategories.length === 1}"
                        color="primary"
                        [checked]="SubCategory.IsChecked"
                        (change)="onChangeSubCategory($event,SubCategory)"
                        [required]="'false'">

                        {{ SubCategory.DataSubCategory }}
                    </mat-checkbox>    
                </section>
            </form>

            <div class="button-wrapper">
                <button mat-button class="back-btn" matStepperPrevious>Back</button>         

                <button 
                    [disabled]="selectedDataSubCategory.length === 0"
                    mat-button 
                    class="next-btn" 
                    color="primary" 
                    matStepperNext>
                    Next
                </button>
            </div>             
        </mat-step>

        <mat-step [stepControl]="formDetailsOfComplaint">
            <ng-template matStepLabel>
                <h3>Details of the Complaint</h3>
            </ng-template>

            <form [formGroup]="formDetailsOfComplaint" class="form-details-of-complaint">     
                <div class="grouped-items full-width">
                    <h3>Select the type of complaint ? <span class="required-field">required</span></h3>
    
                    <mat-radio-group color="primary" formControlName="Form">
                        <mat-radio-button (click)="onSelectComplaintType(6)" [value]="6">Inappropriate Security Measures</mat-radio-button>
                        <mat-radio-button (click)="onSelectComplaintType(9)" [value]="9">Inaccurate Personal Data</mat-radio-button>
                        <mat-radio-button (click)="onSelectComplaintType(11)" [value]="11">Infringement / Violation of Act</mat-radio-button>
                    </mat-radio-group>        
                    <mat-error *ngIf="formDetailsOfComplaint.get('Form').invalid">{{ getSelectErrorMessage() }}</mat-error>
                </div>

                <section class="fadeInUp _delay4ms expand-section expand-section categories">
                    <div>
                        <h3 *ngIf="formDetailsOfComplaint.get('Form').value ===9">The Organisation will not</h3>
                        <h3 *ngIf="formDetailsOfComplaint.get('Form').value ===11">The Organisation</h3>
                    </div>

                    <mat-checkbox
                        *ngFor="let reason of selectedComplaintReasons"
                        color="primary"
                        [ngStyle]="{overflow: 'hidden'}"
                        [checked]="reason.IsChecked"
                        (change)="onChangeComplaintReason($event,reason)"
                        [required]="'false'">
                        {{ reason.ComplaintReasonName }}
                    </mat-checkbox>  
                </section>                             

                <mat-form-field class="full-width margin-top">
                    <mat-label>Provide Full Details Of The Complaint<span class="required-field">required</span></mat-label>
                    <textarea #FullDetailsOfComplaintInput class="textarea-large" placeholder="Enter here" formControlName="FullDetailsOfComplaint" matInput maxlength="1000"></textarea>
                    <mat-hint align="end">{{ FullDetailsOfComplaintInput.value.length }} / 20000</mat-hint>
                    <mat-error *ngIf="formDetailsOfComplaint.get('FullDetailsOfComplaint').invalid">{{ getFullDetailsOfComplaintErrorMessage() }}</mat-error>
                </mat-form-field>                  
            </form>

            <div class="button-wrapper">       
                <button mat-button class="back-btn" matStepperPrevious>Back</button>

                <div class="buttons">
                    <button 
                        mat-button 
                        color="primary" 
                        [ngClass]="{'inline-processing-btn': processing }" 
                        [disabled]="processing || formDetailsOfComplaint.invalid"   
                        class="preview"
                        *ngIf="formComplaintStatus.get('Option').value === 'No'"                  
                        (click)="onPreviewLetter(false)"
                        matStepperNext>
                            <div class="button-label">
                                <mat-icon>visibility</mat-icon>
                                <span>Preview Letter</span>
                            </div>
                    </button>

                    <button
                        [disabled]="formDetailsOfComplaint.invalid || processing"
                        mat-button 
                        color="primary" 
                        [ngClass]="{'inline-processing-btn': processing }" 
                        class="preview"
                        *ngIf="formComplaintStatus.get('Option').value === 'No'"                  
                        (click)="onPreviewLetter(true)"
                        matStepperNext>
                            <div class="button-label">
                                <mat-icon>sim_card_download</mat-icon>
                                <span>Download Letter</span>
                            </div>
                    </button>
                    <!-- removed the submit button, since idealy, clicking on the download, it also automatically submits -->
                    
                    <button
                        *ngIf="formComplaintStatus.get('Option').value === 'Yes' && updateComplaintStatus === false"
                        mat-button 
                        color="primary" 
                        [ngClass]="{'inline-processing-btn': processing }" 
                        class="next-btn submit-btn"                 
                        (click)="onSubmit()">
                            <app-inline-mat-spinner *ngIf="processing"></app-inline-mat-spinner>                        
                            <span>Submit</span>
                    </button>

                    <button
                        *ngIf="formComplaintStatus.get('Option').value === 'Yes' && updateComplaintStatus === true"
                        mat-button 
                        color="primary" 
                        [ngClass]="{'inline-processing-btn': processing }" 
                        [disabled]="processing || !evidenceDocument"     
                        class="next-btn submit-btn"                 
                        (click)="onUpdateComplaint()">
                            <app-inline-mat-spinner *ngIf="processing"></app-inline-mat-spinner>                        
                            <span>Submit</span>
                    </button>
                </div>
            </div>             
        </mat-step>
    </mat-horizontal-stepper>   
</main>
