import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { News, NewsShortner } from 'src/app/pages/home/news/news.component';
import { ApiEndpointsService } from 'src/app/services/api-endpoints.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-read-all-news',
  templateUrl: './read-all-news.component.html',
  styleUrls: ['./read-all-news.component.scss']
})
export class ReadAllNewsComponent implements OnInit, AfterViewInit {

  processing = true;
  routeSub: Subscription;
  routeParams: { slug: string } | null;
  news: News [] = [];
  selected: News;
  httpSub: Subscription;
  otherStoryShortner: NewsShortner = { title: 30, summary: 80 };

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private endpoints: ApiEndpointsService,
    private http: HttpClient,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    this.routeSub = this.route.paramMap.subscribe((params: ParamMap) => {
      this.routeParams = {
        slug: params.get('slug'),
      };
    });      
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this._resizeComponent();

    window.addEventListener('resize', () => {
      this._resizeComponent();
    });   

    setTimeout(() => {
      this.Fetch();
    }, 1000);
  }

  private _resizeComponent(): void {

    if (window.innerWidth > 654 ) {
      
      this.otherStoryShortner = { title: 30, summary: 80 };
      
    } else if (window.innerWidth <= 654 && window.innerWidth > 500) {
      
      this.otherStoryShortner = { title: 80, summary: 150 };
      
    } else if (window.innerWidth <= 500 && window.innerWidth > 360) {
      
      this.otherStoryShortner = { title: 80, summary: 80 };
      
    } else if (window.innerWidth <= 360 && window.innerWidth > 295) {
      
      this.otherStoryShortner = { title: 50, summary: 35 };
      
    } else if (window.innerWidth <= 295) {
      
      this.otherStoryShortner = { title: 140, summary: 0 };
      
    }
  }  

  private Fetch(): void {
    console.log('news:', this.news);

    this.httpSub = this.http.get(this.endpoints.news + '&per_page=6')
    .pipe(catchError(this.service.handleError))
    .subscribe((response: any[]) => {

      response.filter((item, index: number) => {
        if (item['status'] === "publish") {
          this.news.push({
            title: item['title']['rendered'],
            // image: item.acf.image,
            image: this.service.getCMSMediaProxy(item.acf.image),
            content: item.acf.content,
            slug: '/news/' + item.slug,
          });

          if (item['slug'] === this.routeParams.slug) {
            this.selected = this.news[index];
          }          
        }
      });

      this.selected = this.routeParams.slug ? this.selected : this.news[0];
              
      this.processing = false;

    }, (error) => {
      this.processing = false;
      this.service.determineErrorResponse(error);
    });       
  }

  onRead(row: News): void {
    console.log('view vehicle details:', row.slug);   
    // this.service.scollToTop(0);
    this.selected = row;
    this.router.navigate([row.slug]);
    this.changeDetector.detectChanges();
  }  

  ngOnDestroy(): void {
    // this.service.processingBar.next(false);

    if (this.routeSub) { this.routeSub.unsubscribe(); }
  }  
}
