<!-- <footer class="fadeInUp _delay4ms">

    <p>
        UNCDF Copyright &copy; 2021 {{ currentYear > 2021 ? ' - ' + currentYear : '' }} A product of the National Information Technology Authority - Uganda (NITA - U).
    </p>

</footer> -->

<footer>

    <article>

        <section class="about-pdpo">
    
            <div class="wrapper">
    
                <img src="./assets/icons/Final-logos-28.png" alt="" />
                <!-- <img src="./assets/icons/Final-logos-30.png" alt="" /> -->
    
                <p>
                    The Personal Data Protection Office is Uganda’s independent data protection authority. 
                    It is established as an independent office under the National Information Technology 
                    Authority, Uganda (NITA-U) responsible for overseeing the implementation of and 
                    enforcement of the Data Protection and Privacy Act No. 9 of 2019.
                </p>
    
            </div>
    
        </section>
    
        <section class="quick-links">
    
            <h3>Quick Links</h3>
    
            <ul class="footer-list">
                                            
                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/organisations/register">
                        Register
                    </a>
                </li>                
                
                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/file-complaint">
                        File A Complaint
                    </a>
                </li>
                                            
                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/search-register">
                        Search Register
                    </a>
                </li>

                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/information-center/faqs">
                        FAQs
                    </a>
                </li>                

                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/information-center/resources">
                        Resources
                    </a>
                </li>                

                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/information-center/individual">
                        Information for Individuals (Data Subjects)
                    </a>
                </li>                

                <li>
                    <a 
                        (click)="onGoToTop()"
                        routerLink="/information-center/organisation">
                        Information for Organisations
                    </a>
                </li>
                
                
                                            
            </ul>        
    
        </section>
    
        <section class="explore">
    
            <h3>Explore</h3>
    
            <div class="wrapper">
                <a href="https://www.nita.go.ug" target="_blank" mat-raised-button>NITA-U</a>
                
                <a href="https://cert.ug" target="_blank" mat-raised-button>Cert</a>
                
                <a href="https://ict.go.ug" target="_blank" mat-raised-button>Ministry of ICT & National Guidance</a>

                <a href="http://judiciary.go.ug/" target="_blank" mat-raised-button>Judiciary</a>
                
                <a href="https://dpp.go.ug" target="_blank" mat-raised-button>Directorate of Public Prosecutions</a>
            </div>        
    
        </section>
    
        <section class="contact-us">
    
            <h3>Contact Us</h3>
    
            <div class="wrapper">

                <div class="contact">

                    <mat-icon>mail</mat-icon>

                    <div>

                        <a href="mailto:info@pdpo.go.ug" target="_blank">info@pdpo.go.ug</a>

                    </div>

                </div>

                <div class="contact">

                    <mat-icon>phone</mat-icon>

                    <div>

                        <a href="tel:+256 417 801 008" target="_blank">+256 417 801 008</a>

                        <a href="tel:+256 417 801 009" target="_blank">+256 417 801 009</a>

                        <a href="tel:+256 417 801 011" target="_blank">+256 417 801 011</a>

                    </div>

                </div>

            </div>        
    
        </section>

    </article>

    <!-- <div class="copy-right" [ngClass]="{'in-mobile-browser': inMobileBrowser}"> -->
    <div class="copy-right">
        <p>
            Copyright &copy; 2021<span *ngIf="currentYear !== 2021"> - {{ currentYear }}</span> Personal Data Protection Office - NITA-U in Partnership with UNCDF.
        </p>          
    </div>    

</footer>