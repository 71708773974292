import { NgModule } from '@angular/core';

import { MaterialModule } from './material.module';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularCreatePdfModule } from 'angular-create-pdf';

import { ResourceSanitizerPipe } from '../pipes/resource-sanitizer.pipe';
import { TextShortnerPipe } from '../pipes/text-shortner.pipe';
import { TelephoneFormaterDirective } from '../directives/telephone-formater.directive';
import { RemoveSpacesDirective } from '../directives/remove-spaces.directive';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { InlineMatSpinnerComponent } from '../components/inline-mat-spinner/inline-mat-spinner.component';
import { PageBreadcrumbComponent } from '../components/page-breadcrumb/page-breadcrumb.component';
import { OrgMainActivityComponent } from '../dialogs/org-registration/org-main-activity/org-main-activity.component';
import { OrgDataProtectionOfficerComponent } from '../dialogs/org-registration/org-data-protection-officer/org-data-protection-officer.component';
import { OrgSecurityMeasuresComponent } from '../dialogs/org-registration/org-security-measures/org-security-measures.component';
import { OrgThirdPartyComponent } from '../dialogs/org-registration/org-third-party/org-third-party.component';
import { SuccessfulMessageDialogComponent } from '../dialogs/successful-message-dialog/successful-message-dialog.component';
import { OrgNoticeComponent } from '../dialogs/org-registration/org-notice/org-notice.component';
import { QuickLinksComponent } from '../components/quick-links/quick-links.component';
import { OrgCountryComponent } from '../dialogs/org-registration/org-country/org-country.component';
import { OrgUploadComponent } from '../dialogs/org-upload/org-upload.component';
import { CurrencyDirective } from '../directives/currency.directive';
import { OrgForm3DialogComponent } from '../dialogs/org-registration/org-form3-dialog/org-form3-dialog.component';
import { OrgRegistrationPreviewDialogComponent } from '../dialogs/org-registration/org-registration-preview-dialog/org-registration-preview-dialog.component';
import { OrgCertificateDialogComponent } from '../dialogs/org-registration/org-certificate-dialog/org-certificate-dialog.component';
import { ReadAllNewsComponent } from '../components/read-all-news/read-all-news.component';
import { ReadAllUpdatesComponent } from '../components/read-all-updates/read-all-updates.component';
import { ViewPdfDocumentBottomsheetComponent } from '../bottomsheets/view-pdf-document-bottomsheet/view-pdf-document-bottomsheet.component';

@NgModule({
    declarations: [
        ResourceSanitizerPipe,
        TextShortnerPipe,
        TelephoneFormaterDirective,
        RemoveSpacesDirective,
        SnackbarComponent,
        InlineMatSpinnerComponent,
        PageBreadcrumbComponent,
        OrgMainActivityComponent,
        OrgDataProtectionOfficerComponent,
        OrgSecurityMeasuresComponent,
        OrgThirdPartyComponent,
        OrgNoticeComponent,
        OrgCountryComponent,
        SuccessfulMessageDialogComponent,
        QuickLinksComponent,
        OrgUploadComponent,
        CurrencyDirective,
        OrgForm3DialogComponent,
        OrgRegistrationPreviewDialogComponent,
        OrgCertificateDialogComponent,
        ReadAllNewsComponent,
        ReadAllUpdatesComponent,
        ViewPdfDocumentBottomsheetComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AngularCreatePdfModule
    ],
    exports: [
        ResourceSanitizerPipe,
        TextShortnerPipe,
        TelephoneFormaterDirective,
        RemoveSpacesDirective,
        InlineMatSpinnerComponent,
        PageBreadcrumbComponent,
        QuickLinksComponent, 
        CurrencyDirective,
        ReadAllNewsComponent,
        ReadAllUpdatesComponent,
    ],
    entryComponents: [
        SnackbarComponent,
        OrgMainActivityComponent,
        OrgDataProtectionOfficerComponent,  
        OrgSecurityMeasuresComponent,
        OrgThirdPartyComponent,   
        OrgNoticeComponent,  
        SuccessfulMessageDialogComponent,  
        OrgCountryComponent,    
        OrgUploadComponent,
        OrgForm3DialogComponent,
        OrgRegistrationPreviewDialogComponent,
        OrgCertificateDialogComponent,
        ViewPdfDocumentBottomsheetComponent,
    ],
    providers: [
        ResourceSanitizerPipe,
        CurrencyPipe,
        DecimalPipe,
        DatePipe
    ]
})
export class SharedModule {}
