import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showMenu = false;

  constructor( private service: ApiService ) { }

  ngOnInit(): void {
  }

  onGoToTop(): void {
    this.service.scollToTop(0);
  }  

  onOpenURL(): void {
    window.open(environment.backOffice, '_blank');
  }

  onToggleMenu(): void {
    this.showMenu = !this.showMenu;
  }
}
