import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { ApiEndpointsService } from 'src/app/services/api-endpoints.service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import * as html2pdf from 'html2pdf.js';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ApiPayload, CompanyRegistrationModel } from 'src/app/services/api.model';

interface Data {
  ComplaintForm: any,
  Organisation: CompanyRegistrationModel,
  OrganisationComplainedFrom: string;
  Date: Date;
  autoDownload: boolean,
}

@Component({
  selector: 'app-complaint-letter-dialog',
  templateUrl: './complaint-letter-dialog.component.html',
  styleUrls: ['./complaint-letter-dialog.component.scss']
})
export class ComplaintLetterDialogComponent implements OnInit {

  donwloading = false;

  constructor(
    private dialogRef: MatDialogRef<ComplaintLetterDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private http: HttpClient,
    private service: ApiService,
    private endpoints: ApiEndpointsService,
    private datePipe: DatePipe,
  ) { 
    console.log('data:', data);
  }

  ngOnInit(): void {
    if (this.data.autoDownload) {
      this.donwloading = true;
      this.onDownloadLetter();
    }
  }

  onCloseWindow(): void {
    this.dialogRef.close();
  }

  getDateSuperscript(): string {
    let day = parseInt(this.datePipe.transform(this.data.Date, 'd'));

    return this.service.getDateSuperscript(day)
  }

  onDownloadLetter(): void {

    setTimeout(() => {
      this.service.openSnackBar('Downloading. Please wait.', 'success');

      const options = {
        filename: this.data.ComplaintForm.FirstName + "'s Complaint Letter.pdf",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          backgroundColor: null,
        },
        jspdf: {
          unit: 'in',
          format: 'letter',
          orientation: 'portrait'
        },
        margin: 15,
      };
  
      const content: Element = document.getElementById('letterBodyContainer');
  
      html2pdf()
      .from(content)
      .set(options)
      .save()
      .then((onFulfilled) => {
  
        setTimeout(() => {
          this.donwloading = false;

          this.onCloseWindow();
          
          this.service.openSnackBar('Downloaded. Please check in your downloads.', 'success-lg');
        }, 1000);

      }); 
    }, 500);
  }
}

