<main [ngClass]="{'status-true': data.status === true, 'status-false': data.status === false}">
    <div class="mat-progress-bar-container">
        <mat-progress-bar *ngIf="processing" mode="indeterminate"></mat-progress-bar>
    </div>

    <header>
        <h1 [innerHTML]="data.title"></h1>

        <button mat-button (click)="onCloseDialog()">
            <mat-icon>clear</mat-icon>
        </button>
    </header>

    
        
    
    <section style="width:800px;height:800px;line-height:3em;overflow:scroll; padding: '50px'">

        <p [innerHTML]="data.message"></p>

        <button 
        mat-button
        (click)="onCloseDialog()"
        color="primary"
        [ngStyle]="{'margin-left': '350px', 'background-color': 'green', color: 'white'}">
        OK
        </button>

    </section>   
</main>