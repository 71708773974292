<header>

    <article>

        <aside class="fadeIn _delay1ms">
            <!-- <img src="./assets/icons/logo2.png" alt="" /> -->
            <img src="./assets/icons/Final-logos-28.png" alt="" />
        </aside>
    
        <ul class="main-menu">
            <li class="home-li fadeInDown _delay2ms">
                <button
                    mat-button
                    routerLink="/home" 
                    title="Home"
                    (click)="onGoToTop()"
                    routerLinkActive="active-route">
                        Home
                </button>
            </li>

            <li class="home-li fadeInDown _delay3ms">
                <button
                    mat-button
                    routerLink="/about-us" 
                    title="Home"
                    (click)="onGoToTop()"
                    routerLinkActive="active-route">
                        About Us
                </button>
            </li>

            <!-- <li class="home-li fadeInDown _delay3ms">
                <button
                    mat-button
                    routerLink="/search-register" 
                    title="Home"
                    (click)="onGoToTop()"
                    routerLinkActive="active-route">
                        Get A Certified Copy
                </button>
            </li> -->
            
            <li class="data-subjects-li fadeInDown _delay4ms">
                <button
                    mat-button
                    title="Services"
                    routerLink="/file-complaint"
                    routerLinkActive="active-route">
                    File A Complaint
                    <!-- <div class="btn-label">
                        <span>Make A Complaint</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div> -->
                </button>
    
                <!-- <ul role="menu" class="dropdown-menu fadeInUp _delay5ms">
                    <li class="menu-item">
                        <a 
                            title="Register complaint" 
                            class="child-dropdown-label"
                            (click)="onGoToTop()">
                            Register complaint
                        </a>

                        <ul role="menu" class="dropdown-sub-menu fadeInUp _delay5ms">
                            <li class="menu-item">
                                <a title="Register complaint" (click)="onGoToTop()" routerLink="/data-subjects/register-complaint-form6" routerLinkActive="active-route">
                                    Form 6 - Inappropriate Security Measures
                                </a>
                            </li>

                            <li class="menu-item">
                                <a title="Register complaint" (click)="onGoToTop()" routerLink="/data-subjects/register-complaint-form9" routerLinkActive="active-route">
                                    Form 9 - Inaccurate Personal Data
                                </a>
                            </li>
            
                            <li class="menu-item">
                                <a title="Register complaint" (click)="onGoToTop()" routerLink="/data-subjects/register-complaint-form11" routerLinkActive="active-route">
                                    Form 11 - Infringement / Violation of Act
                                </a>
                            </li>
                        </ul>                           
                    </li> -->
    
                    <!-- <li class="menu-item">
                        <a title="Make an inquiry" (click)="onGoToTop()" routerLink="/data-subjects/make-an-inquiry" routerLinkActive="active-route">
                            Make An Inquiry
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Track Progress of a complaint" (click)="onGoToTop()" routerLink="/data-subjects/make-an-inq[luiry" routerLinkActive="active-route">
                            Give an Instruction
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Track Progress of a complaint" (click)="onGoToTop()" routerLink="/data-subjects/make-an-lo" routerLinkActive="active-route">
                            Track Progress of Complaint
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Track Progress of a complaint" (click)="onGoToTop()" routerLink="/data-subjects/make-an" routerLinkActive="active-route">
                            Rights of a Data Subject
                        </a>
                    </li> -->
                <!-- </ul>                  -->
            </li>     
            
            <li class="home-li fadeInDown _delay3ms">
                <button
                    mat-button
                    title="Report a Breach"
                    (click)="onOpenURL()"
                    routerLinkActive="active-route">
                        Report A Breach
                </button>
            </li>
            
            <li class="home-li fadeInDown _delay3ms">
                <button
                    mat-button
                    routerLink="/search-register" 
                    title="Search"
                    (click)="onGoToTop()"
                    routerLinkActive="active-route">
                        Search Register
                </button>
            </li>

            <!-- <li class="home-li fadeInDown _delay3ms">
                <button
                    mat-button
                    routerLink="/verify" 
                    title="Verify"
                    (click)="onGoToTop()"
                    routerLinkActive="active-route">
                        Verify
                </button>
            </li>             -->
            
            <!-- <li class="organisations-li fadeInDown _delay5ms">
                <button
                    mat-button
                    title="Organisations"
                    routerLink="/organisations/register-organisation"
                    routerLinkActive="active-route">
                    Register Organisation
                </button>
            </li>            -->

            <!-- <li class="organisations-li fadeInDown _delay5ms">
                <button
                    mat-button
                    title="Create Account"
                    routerLink="/organisations/create-account"
                    routerLinkActive="active-route">
                    Create Account
                </button>
            </li>            -->
            
            <!-- <li class="organisations-li fadeInDown _delay5ms">
                <button
                    mat-button
                    title="Organisations"
                    routerLink="/organisations/register-organisation"
                    routerLinkActive="active-route">
                    Register Organisation
                    <div class="btn-label">
                        <span>Register Organisation</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                </button>
    
                <ul role="menu" class="dropdown-menu fadeInUp _delay5ms">
                    <li class="menu-item">
                        <a title="Register" (click)="onGoToTop()" routerLink="/organisations/new-registration" routerLinkActive="active-route">
                            New Registration
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Report data breach" (click)="onGoToTop()" routerLink="/organisations/report-data-breach" routerLinkActive="active-route">
                            Report Data Breach
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Track Status" (click)="onGoToTop()" routerLink="/organisations/new-registraon" routerLinkActive="active-route">
                            Track Status
                        </a>
                    </li>
                </ul>                 
            </li>            -->
            
            <li class="information-center-li fadeInDown _delay6ms">
                <button
                    mat-button
                    title="Information Center"
                    routerLink="/information-center" 
                    title="Home"
                    (click)="onGoToTop()"                    
                    routerLinkActive="active-route">
                        Information Center
                </button>              
            </li>           
            
            <!-- <li class="information-center-li fadeInDown _delay6ms">
                <button
                    mat-button
                    title="Information Center"
                    routerLink="/information-center" 
                    title="Home"
                    (click)="onGoToTop()"                    
                    routerLinkActive="active-route">
                    <div class="btn-label">
                        <span>Information Center</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                </button>
    
                <ul role="menu" class="dropdown-menu fadeInUp _delay5ms">
                    <li class="menu-item">
                        <a title="Data Protection Law" (click)="onGoToTop()" routerLink="/services/ferry-services" routerLinkActive="active-route">
                            Data Protection Law
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Rights of a Data Subject" (click)="onGoToTop()" routerLink="/services/maintenance-services" routerLinkActive="active-route">
                            Rights of a Data Subject
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Obligations of organisations" (click)="onGoToTop()" routerLink="/services/maintenance-services" routerLinkActive="active-route">
                            Obligations of Organisations
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="Frequently Asked Questions" (click)="onGoToTop()" routerLink="/services/maintenance-services" routerLinkActive="active-route">
                            FAQs
                        </a>
                    </li>
    
                    <li class="menu-item">
                        <a title="How to use this website" (click)="onGoToTop()" routerLink="/services/maintenance-services" routerLinkActive="active-route">
                            How to use this Website
                        </a>
                    </li>
                </ul>                 
            </li>            -->
            
            <!-- <li class="menu-trigger-li fadeInLeft _delay3ms">
                <button mat-button matTooltip="Open the menu" [matMenuTriggerFor]="menu" >
                    <mat-icon>more_horiz</mat-icon>
                </button>
            </li> -->
        </ul>
    
        <div class="fadeInRight _delay4ms login-button">
            <button 
                mat-button 
                class="create-account"
                routerLinkActive="activate-route"
                routerLink="/register"
                color="primary">
                Register
            </button>

            <button 
                mat-raised-button 
                class="login-btn"
                color="primary"
                (click)="onOpenURL()">
                Login
            </button>
        </div>

        <button [matMenuTriggerFor]="menu" class="menu-button" mat-button (click)="onToggleMenu()">
            <mat-icon> {{ showMenu ? 'menu_open' : 'menu' }} </mat-icon>
        </button>    

    </article>

</header>

<mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="pdpo-header-mat-menu pdpo-projects-header-menu">
    
    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="Home" 
        (click)="onGoToTop();onToggleMenu()"
        routerLink="/home" 
        routerLinkActive="activate-route">
        <span>Home</span>
    </button>
    
    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="About Us" 
        (click)="onGoToTop();onToggleMenu()"
        routerLink="/about-us" 
        routerLinkActive="activate-route">
        <span>About Us</span>
    </button>
    
    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="File A Complaint" 
        (click)="onGoToTop();onToggleMenu()"
        routerLink="/file-complaint" 
        routerLinkActive="activate-route">
        <span>File A Complaint</span>
    </button>
    
    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="File A Complaint" 
        (click)="onOpenURL();onToggleMenu()"
        routerLink="/file-complaint" >
        <span>Report A Breach</span>
    </button>

    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="Search Register" 
        (click)="onGoToTop();onToggleMenu()"
        routerLink="/search-register" 
        routerLinkActive="activate-route">
        <span>Search Register</span>
    </button>    

    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="Information Center" 
        (click)="onGoToTop()"
        routerLink="/information-center" 
        routerLinkActive="activate-route">
        <span>Information Center</span>
    </button>    

    <button 
        mat-menu-item 
        class="main-menu-btn create-account"
        title="Register" 
        (click)="onGoToTop();onToggleMenu()"
        routerLink="/register" 
        routerLinkActive="activate-route">
        <span>Register</span>
    </button>    

    <button 
        mat-menu-item 
        class="main-menu-btn"
        title="File A Complaint" 
        (click)="onOpenURL();onToggleMenu()"
        routerLink="/file-complaint" >
        <span>Login</span>
    </button>    

</mat-menu>    