<main>
    <button mat-button [disabled]="donwloading" class="close-window-btn" (click)="onCloseWindow()">
        <mat-icon>clear</mat-icon>
    </button>

    <article
        style="width: 100%; margin: 0px 0px 30px; position: relative;"
        id="letterBodyContainer">
        
        <section class="letter-body" style="padding: 65px 40px 45px;font-family: none;">

            <h3 style="font-size: 16px;text-transform: capitalize;text-align: center;position: absolute;right: 40px;top: 23px;font-family: none;font-weight: 300;font-style: italic;color: #000;">
                Regulation 16(4)
            </h3>

            <h1 style="font-weight: 700;font-size: 17px;text-transform: uppercase;text-align: center;font-family: none;color: #000;margin-top: 0px;">
                FORM 3
            </h1>

            <h1 style="font-size: 17px;text-transform: uppercase;text-align: center;font-family: none;color: #000;line-height: 25px;padding: 0px;width: 100%;margin: -3px 0px 0px;">
                UNDERTAKING NOT TO PROCESS OR STORE PERSONAL DATA OUTSIDE UGANDA
            </h1>

            <p style="font-size: 16px;font-family: none;line-height: 21px;color: #000000;">
                I, <i style="font-size: 15px;font-family: none;border-bottom: 1px dashed;padding: 0px 40px;font-weight: 600;color: #000;">{{ data.OrganisationName }}, {{ data.PhysicalAddress }}</i> 
                <i style="font-size: 15px;font-family: none;line-height: 21px;color: #000000;">(insert full name and address of applicant)</i> 
                undertake not to process or store personal data in a country outside Uganda unless such country has
                adequate measures in place for the protection of personal data at least equivalent to
                the protection provided for by the Act or permitted under the Act and Regulations
                made under the Act.
            </p>
            
            <div style="text-align: left;font-size: 16px;font-family: none;line-height: 27px;color: #1c1c1c;margin: 25px 0px;display: flex;flex-direction: row;">
                Dated this <div style="font-size: 16px;font-family: none;border-bottom: 1px dashed;padding: 0px 8px 0px;color: #000;margin: -12px 7px 0px;height: 31px;font-style: italic;font-weight: bold;">{{ data.Date | date : 'dd' }} <sup style="font-size: 16px;font-family: none;">{{ getDateSuperscript() }}</sup></div> 
                day of <i style="font-size: 16px;font-family: none;border-bottom: 1px dashed;padding: 0px 9px 0px;color: #000;margin: -4px 7px -2px;height: 23px;font-style: italic;font-weight: bold;">{{ data.Date | date : 'MMMM' }}</i>
                , 20 <i style="font-size: 16px;font-family: none;border-bottom: 1px dashed;padding: 0px 10px 0px;color: #000;margin: -4px 2px -2px;height: 23px;font-style: italic;font-weight: bold;">{{ data.Date | date : 'YY' }}</i>
            </div>
            
            <p style="text-align: left; font-size: 16px;font-family: none;line-height: 27px;color: #1c1c1c; margin: 0px;">
                Signature of person making undertaking
            </p>
            
            <div>
                <p style="font-size: 16px;font-family: none;line-height: 27px;color: #1c1c1c; margin: 10px 0px 25px; text-align: left;">
                    Declared on this <i style="font-size: 16px;font-family: none;border-bottom: 1px dashed;padding: 0px 10px 0px;color: #000;margin: -9px 2px -2px; height: 23px;font-style: italic;font-weight: bold;position: relative;top: -4px;">{{ data.Date | date : 'dd' }} <sup style="font-size: 16px;font-family: none;">{{ getDateSuperscript() }}</sup></i> 
                    day of <i style="font-size: 16px;font-family: none;border-bottom: 1px dashed;padding: 0px 10px 0px;color: #000;margin: -9px -3px -2px 2px; height: 23px;font-style: italic;font-weight: bold;position: relative;top: -4px;">{{ data.Date | date : 'MMMM' }}</i>
                    , 20 <i style="font-size: 16px;font-family: none;border-bottom: 1px dashed;padding: 0px 10px 0px;color: #000;margin: -9px 2px -2px -4px; height: 23px;font-style: italic;font-weight: bold;position: relative;top: -4px;">{{ data.Date | date : 'YY' }}</i>, 
                    at 
                </p>

                <div></div>
            </div>

            <div style="margin-top: 30px; width: 100%; border-bottom: 1px dashed black; "></div>

            <div style="font-size: 16px; font-family: none;padding: 0px 15px;color: #000;margin: 4px 0px 80px -15px;font-style: italic;">(state place)</div>
    
            <div style="font-size: 16px; font-family: none;">
                <div style="width: 290px; border-bottom: 1px dashed black; "></div>
                <p style="font-size: 16px;font-family: none;color: #000;margin: 8px 0px 1px 0px;">Signature of person making undertaking</p>
            </div>
    
            <div style="font-size: 16px; font-family: none; display: flex; flex-direction: column; width: 100%; align-items: center; margin-top: 100px;">
                <span style="font-size: 16px;font-family: none;margin: 0px 0px 5px 0px;color: #000;">Before me</span>

                <div style="width: 290px;border-bottom: 1px dashed black;margin: 5px 0px 0px 0px;color: #000;"></div>

                <i style="font-size: 16px;font-family: none;margin: 5px;color: #000;">Commissioner for Oaths</i>
            </div>

        </section>

    </article>
</main>