import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Update } from 'src/app/pages/home/updates/updates.component';
import { ApiEndpointsService } from 'src/app/services/api-endpoints.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-read-all-updates',
  templateUrl: './read-all-updates.component.html',
  styleUrls: ['./read-all-updates.component.scss']
})
export class ReadAllUpdatesComponent implements OnInit {

  processing = true;
  routeSub: Subscription;
  routeParams: { slug: string } | null;
  updates: Update [] = [];
  selected: Update;
  httpSub: Subscription;
  page = 1;
  loadMore = true;

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private endpoints: ApiEndpointsService,
    private http: HttpClient,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    this.routeSub = this.route.paramMap.subscribe((params: ParamMap) => {
      this.routeParams = {
        slug: params.get('slug'),
      };
    });      
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.Fetch();
    }, 500);    
  }

  private Fetch(): void {
    this.httpSub = this.http.get(this.endpoints.updates + '&per_page=4&page=' + this.page)
    .pipe(catchError(this.service.handleError))
    .subscribe((response: any[]) => {
      response.filter((item, index: number) => {
        if (item['status'] === "publish") {
          this.updates.push({
            title: item['title']['rendered'],
            // image: item.acf.image,
            image: this.service.getCMSMediaProxy(item.acf.image),
            content: item.acf.content,
            slug: '/updates/' + item.slug,
          });

          if (item['slug'] === this.routeParams.slug) {
            this.selected = this.updates[index];
          }          
        }
      });

      this.selected = this.routeParams.slug ? this.selected : this.updates[0];
              
      this.processing = false;

    }, (error) => {
      this.processing = false;
      if (error.error.code === 'rest_post_invalid_page_number') {
        this.loadMore = false;
        this.service.openSnackBar('Sorry, all the updates have been loaded', 'success-lg');
      } else {
        this.service.determineErrorResponse(error);
      }
    });       
  }

  onLoadMore(): void {
    if (this.loadMore) {
      this.processing = true;
      ++this.page;
      this.Fetch();
    }
  }

  ngOnDestroy(): void {
    // this.service.processingBar.next(false);

    if (this.routeSub) { this.routeSub.unsubscribe(); }
  }  
}
