<main class="fadeInUp _delay4ms">

    <app-page-breadcrumb [marginTop]="'-15px'" [title]="'Search Register'"></app-page-breadcrumb>

    <article>

        <section class="wrapper" *ngIf="certificateValidity">

            <h1 style="color: green; margin-bottom: 50px; font-size: 50px;">VALID CERTIFICATE</h1>

            <p style="font-size: 20px;">
                To obtain a certified copy of the certificate or an extract, please contact the PDPO.                
            </p>

            <mat-grid-list cols="4" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-grid-tile-header>Date of Issue</mat-grid-tile-header>
                    <h4>{{dateOfIssue}}</h4>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-grid-tile-header>Certificate Number</mat-grid-tile-header>
                    <h4>{{displayCertificateNumber}}</h4>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-grid-tile-header>Organization Name</mat-grid-tile-header>
                    <h4>{{organisationName}}</h4>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-grid-tile-header>Certificate Status</mat-grid-tile-header>
                    <h4>{{certificateStatus}}</h4>
                </mat-grid-tile>
            </mat-grid-list>

        </section>

        <section  class="wrapper"  *ngIf="certificateValidity == false">
            <h1 style="color: red; margin-bottom: 50px; font-size: 50px;">INVALID CERTIFICATE NUMBER</h1>
            <h3 style="text-align: center;">Contact the Data Protection Officer for more inquiries</h3>
        </section>
       

    </article>
</main> 
