import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiEndpointsService {

  /* Wordpress Content Management System End Points */
  carousel = environment.cmsBaseUrlProxy + 'home-carousel?_embed';
  news = environment.cmsBaseUrlProxy + 'news?_embed';
  updates = environment.cmsBaseUrlProxy + 'updates?_embed';
  aboutUs = environment.cmsBaseUrlProxy + 'about-us?_embed';
  infoResources = environment.cmsBaseUrlProxy + 'info_resources?_embed';
  infoFAQs = environment.cmsBaseUrlProxy + 'info_faqs?_embed';
  infoOrganisation = environment.cmsBaseUrlProxy + 'info-organisation?_embed';
  infoIndividual = environment.cmsBaseUrlProxy + 'info_individual?_embed';

  /* Python API End Points */
  orgRegistration = environment.pythonApi + 'org-registration';
  complaints = environment.pythonApi + 'complaints';
  countries = environment.pythonApi + 'reference/countries';
  dataSources = environment.pythonApi + 'reference/data_source';
  organisationType = environment.pythonApi + 'reference/organisation_type';
  sector = environment.pythonApi + 'reference/sector';
  dataSubcategories = environment.pythonApi + 'reference/get_category_subcategories';
  dataPurpose = environment.pythonApi + 'reference/data_purpose';
  natureOfBusiness = environment.pythonApi + 'reference/nature-of-business';
  getOrganisations = environment.pythonApi + 'complaints/getOrganisations';
  getOrganisation = environment.pythonApi + 'org-registration/byorgid/single';
  getOrganisationSingle = environment.pythonApi + 'org-registration/single';
  createAccount = environment.pythonApi + 'useraccount/org/new';
  dataProtectionRegister = environment.pythonApi + 'data-protection-register';
  getComplaintReasons = environment.pythonApi + 'complaints/get-complaint-reasons';
  getSingleComplaint = environment.pythonApi + 'complaints/single';
  updateComplaint = environment.pythonApi + 'complaints/update-complaint';


  verifyCertificate = environment.pythonApi + 'data-protection-register/verify-certficate'

  constructor() { }

}
