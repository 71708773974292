import { Component, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiEndpointsService } from 'src/app/services/api-endpoints.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { trigger, query, style, transition, stagger, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ApiPayload } from 'src/app/services/api.model';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-verify-certificate',
  templateUrl: './verify-certificate.component.html',
  styleUrls: ['./verify-certificate.component.scss']
})
export class VerifyCertificateComponent implements OnInit {

  certificateNumber:string;
  httpSub: Subscription;

  displayCertificateNumber:string;
  dateOfIssue:string
  certificateStatus:string;
  organisationName:string;
  certificateValidity = false;


  constructor(
    private http: HttpClient,
    private service: ApiService,
    private endPoints: ApiEndpointsService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe,
    private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.certificateNumber = this.activatedRoute.snapshot.paramMap.get("certificate-number");
    console.log(this.certificateNumber);

    this.verifyCertificate();

    
    
  }

  verifyCertificate(): void {
    
    this.httpSub = this.http.get<ApiPayload>(this.endPoints.verifyCertificate+'?CertificateNo='+this.certificateNumber)
    .pipe(catchError(this.service.handleError))
    .subscribe((response) => {
      console.log(response[0]);
      if(response[0]){
        this.dateOfIssue = response[0].DateOfIssue;
        this.displayCertificateNumber = response[0].CertificateNumber;
        this.certificateStatus = response[0].Status;
        this.organisationName = response[0].Organisation;

        this.certificateValidity = true;
  
      }
      else{
        this.certificateValidity = false
      }
      
    }, (error) => {
 
      this.service.determineErrorResponse(error);
    });
  }




}
